<template>
  <div
    class="login-form"
  >
    <form
      id="new_customer"
      class="simple_form second-account-form checkout-login"
      novalidate="novalidate"
      action="/customers/sign_in"
      accept-charset="UTF-8"
      data-remote="true"
      method="post"
      @submit.prevent="submitForm"
    >
      <input
        name="utf8"
        type="hidden"
        value="✓"
      >
      <div class="form-group field control">
        <input
          id="customer_email"
          v-model="checkoutEmail"
          type="hidden"
          name="customer[email]"
          rules="required"
        >
        <p>{{ $t('checkout.payment.preexistingAccount') }}</p>
        <input
          id="customer_password"
          v-model="checkoutPassword"
          autocomplete="off"
          :placeholder="$t('form.password')"
          class="form-control password input second-account-form-password"
          type="password"
          name="customer[password]"
        >
        <div class="response" />
      </div>
      <div
        v-if="error"
        class="error"
      >
        {{ error }}
      </div>
      <div class="form-group submit field control">
        <input
          type="hidden"
          name="checkoutLogin"
          value="true"
        >
        <input
          v-if="!xGamesSite"
          id="loginBtn"
          type="submit"
          name="commit"
          value="Log In"
          class="btn btn-primary login-btn"
          data-disable-with="submit"
        >
        <button
          v-if="xGamesSite"
          id="loginBtn"
          type="submit"
          name="commit"
          value="Log In"
          class="btn btn-primary login-btn"
          data-disable-with="submit"
        >
          <span 
            v-if="xGamesSite"
            class="btn-text"
          >{{ $t('signIn.login') }}</span>
        </button>
      </div>
    </form>
    <div class="forgot-password-link">
      <a href="/customers/password/new">{{ $t('forgotYourPassword') }}</a>
    </div>
  </div>
</template>

<script>
import CartApi from '../cart/cart_api'
export default {
  props: {
    email: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      checkoutEmail: this.email,
      checkoutPassword: '',
      error: null
    }
  },
  computed: {
    postLoginUrl() {
      return `/${window.locale}/cart/checkout`
    }
  },
  methods: {
    xGamesSite() {
      if(window.name === 'x-games') {
        return true
      }
    },

    submitForm() {
      const body = JSON.stringify({ customer:{

        email: this.checkoutEmail,
        password: this.checkoutPassword,
        store_location_for: this.postLoginUrl,
        checkoutLogin: true,
      },
      utf8: '✓'
      })
      CartApi.checkoutLogin(body).then(resp => {
        if(resp.logged_in){
          window.location.href = this.postLoginUrl
        } else {
          this.error = resp.error
        }

      })
    }
  }
}
</script>
