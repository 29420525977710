<template>
  <div class="money-info">
    <h4 class="subtotal">
      {{ $t("formPackage.subtotal") }}

      <currency-convert
        :amount="subtotal"
        :currency="currency"
      />
    </h4>

    <div v-if="hasPaymentTerms">
      <h4 class="or-payment-terms">
        {{ $t("formPackage.paymentCount", { count: maxPaymentTermOptions }) }}

        <currency-convert
          :amount="lowestPaymentSubtotal"
          :currency="currency"
        />
      </h4>

      <p class="or-payment-terms">
        <small>
          {{ dueDatesText }}
        </small>
      </p>
    </div>
  </div>
</template>

<script>
/* global locale */

import Fraction from 'fraction.js'
import { CurrencyConvert } from '../../../default/currency_convert'

export default {
  components: {
    CurrencyConvert
  },

  data() {
    return {
      maxPaymentTermOptions: 0,
      paymentTerms: []
    }
  },

  computed: {
    discount() {
      if (this.$store.state.packages.selectedPackage != null && this.$store.state.packages.selectedPackage.apply_discount) {
        return this.$store.state.packages.selectedPackage.discount * this.$store.state.packages.packageQty * this.rate
      } else {
        return 0
      }
    },
    order(){
      return this.$store.state.order.order
    },

    currency(){
      if(this.order){
        return this.order.currency
      }else {
        return 'USD'
      }
    },

    rate(){
      if(this.order){
        return this.order.exchange_rate
      }else {
        return 1.0
      }
    },

    dueDates() {
      let dates = []

      this.paymentTerms.forEach(term => {
        if (term.paymentCountGroup == this.maxPaymentTermOptions && term.dueDate > new Date()) {
          dates.push(this.dateText(term.dueDate))
        }
      })

      dates.sort( (a,b)=> {
        return new Date(a) - new Date(b)
      })

      return dates
    },

    dueDatesText() {
      if (this.dueDates.length > 1) {
        return this.$t('formPackage.dueDateTextMultiple',
          { datesList: this.dueDates.join(' & on ') })
      } else {
        return this.$t('formPackage.dueDateTextTwo', { date: this.dueDates.join('') })
      }
    },

    hasPaymentTerms() {
      return this.maxPaymentTermOptions >= 1
    },

    lowestPaymentSubtotal() {
      return (this.subtotal / this.maxPaymentTermOptions)
    },

    subtotal() {
      if (this.$store.state.packages.selectedPackage == null) { return 0 }

      let subtotal = this.$store.state.packages.selectedPackage.price * this.$store.state.packages.packageQty
      return subtotal * this.rate
    },
  },

  watch: {
    pack() {
      this.setPaymentTerms()
    }
  },

  created() {
    this.setPaymentTerms()
  },

  methods: {
    dateText(date) {
      if (locale == 'en-GB') {
        return date.toLocaleDateString(
          locale,
          { day: 'numeric', month: 'long', year: 'numeric' }
        )
      } else {
        return date.toLocaleDateString(
          locale,
          { month: 'long', day: 'numeric', year: 'numeric' }
        )
      }
    },

    setPaymentTerms() {
      this.maxPaymentTermOptions = 0
      this.paymentTerms = []

      if (this.$store.state.packages.selectedPackage == null) { return }

      this.$store.state.packages.selectedPackage.event_payment_terms.forEach(term => {
        if (new Date(term.start_date) <= new Date() && new Date() < new Date(term.end_date)) {
          let updatedTerm = term
          updatedTerm.paymentCountGroup = new Fraction(term.payment_type).d
          updatedTerm.dueDate = new Date(term.due_date)
          this.maxPaymentTermOptions = Math.max(this.maxPaymentTermOptions, updatedTerm.paymentCountGroup)
          this.paymentTerms.push(updatedTerm)
        }
      })
    }
  }
}
</script>
