<template>
  <div>
    <div
      v-if="packages.length > 0"
      id="addToCartVue"
      class="purchasing_box"
    >
      <div id="packageForm">
        <div
          class="heading"
        >
          <label
            class="occupancy-desc-box"
            :class="[isGpeEvent() ? 'gpe' : 'f1e', packages.length > 1 ? '' : 'single-package']"
          >
            <span>
              {{ title() }}
            </span>
            <i class="fa fa-chevron-down" />
            <span class="arrow" />
          </label>
          <CurrencyDropdown @currency-emit="updateCurrency" />
        </div>

        <div class="packages_wrapper">
          <SubPackageInputFields
            v-if="checkForSubpackage()"
            :currency="currency"
            :rate="rate"
            :packages="packages"
          />

          <SoloPackageInputField
            v-else
            :currency="currency"
            :rate="rate"
            :packages="packages"
          />
        </div>

        <div class="new_order_item">
          <AddToCart
            :form-btn-text="formBtnText"
            :qtyhotel="hotelQty"
            :qtypackage="packageQty"
            :idpackage="idPackage"
            @order-item-added="redirectToOrderItemHotels"
          />
        </div>

        <h4 class="subtotal">
          {{ $t("formPackage.subtotal") }}

          <CurrencyConvert
            :amount="subtotal"
            :currency="currency"
          />
        </h4>

        <CurrencyDisclaimer
          :currency="currency"
          :chargeablecurrencies="chargeableCurrencies"
        />

        <div>
          <PriceInfo
            :currency="currency"
            :rate="rate"
          />
        </div>

        <div>
          <span class="tax_disclaimer small">
            {{ $t('taxDisclaimer') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global locale */

import { CurrencyDropdown, CurrencyDisclaimer, CurrencyConvert } from '../../default/currency_convert'
import AddToCart from './add-to-cart'
import SubPackageInputFields from '../../shared/add_to_cart_forms/h4h_bottom_form/subpackage-input-fields'
import SoloPackageInputField from '../../shared/add_to_cart_forms/h4h_bottom_form/single-package-input-field'
import PriceInfo from '../../shared/add_to_cart_forms/h4h_bottom_form/price-info'
// import FetchCall from '../../local_fetch_call'


export default {
  name: 'GpeH4hForm',
  components: {
    AddToCart,
    CurrencyConvert,
    CurrencyDisclaimer,
    CurrencyDropdown,
    SubPackageInputFields,
    PriceInfo,
    SoloPackageInputField
  },

  data() {
    return {
      chargeableCurrencies: [],
      hotelQty: 0,
      packageQty: 0,
      packages: [],
    }
  },

  computed: {
    formBtnText() {
      if(!this.$store.state.packages.validGpePackage) {
        return this.$t('formPackage.soldOut')
      }
      return this.$t('formPackage.addToCart')
    },

    idPackage() {
      if (this.$store.state.packages.selectedPackage) {
        return this.$store.state.packages.selectedPackage.id
      } else {
        return null
      }
    },

    order(){
      return this.$store.state.order.order
    },

    currency(){
      if(this.order){
        return this.order.currency
      }else {
        return 'USD'
      }
    },

    rate(){
      if(this.order){
        return this.order.exchange_rate
      }else {
        return 1.0
      }
    },

    subtotal() {
      if (this.$store.state.packages.selectedPackage == null) { return 0 }

      let subtotal = this.$store.state.packages.selectedPackage.price * this.$store.state.packages.packageQty
      return subtotal * this.rate
    },
  },

  created() {
    let h4hPackageDiv = document.getElementById('h4hPackageCartFormData')

    if (h4hPackageDiv) {
      this.packages = JSON.parse(h4hPackageDiv.dataset.packages)
      this.chargeableCurrencies = this.packages[0].chargeable_currencies
    }
  },

  methods: {
    updateCurrency(errors, currency, rate) {
      if (currency && rate) {
        this.currency = currency
        this.rate = rate
      }
    },

    checkForSubpackage(){
      return (this.packages.length > 1 && this.packages[0].group!=='Package')
    },

    redirectToOrderItemHotels(item) {
      if (item.id) {
        if(item.hotel_behavior!='no_hotels'){
          window.location.replace(`/${locale}/cart/items/${item.id}/hotels`)
        } else {
          this.openCart()
        }
      }
    },

    openCart() {
      document.getElementById('navCartLink').click()
    },

    isGpeEvent() {
      return this.packages[0].event_type == 'gpe'
    },

    title() {
      if(this.isGpeEvent()) {
        return this.packages.length > 1 ? 'Step 1: ' + this.$t('formPackage.chooseYourPackage') : this.$t('formPackage.yourTicket')
      } else {
        return 'Step 1: ' + (this.packages.length > 1 ? this.$t('formPackage.chooseYourPackage') : this.$t('formPackage.chooseYourQuantity'))
      }
    }
  }
}
</script>
