<template>
  <transition name="modal-fade">
    <div class="rate-change modal-backdrop">
      <div class="rate-change-modal">
        <section
          id="modalDescription"
          class="modal-body"
        >
          <div class="clear-cart-text">
            <b>{{ $t("checkout.payment.headsUp") }}</b>
            {{ $t("checkout.payment.rateChanged", {total: total} ) }}
          </div>
        </section>
        <footer class="modal-footer">
          <div
            class="footer-buttons"
            name="footer"
          >
            <button
              class="btn btn-secondary"
              @click="accept"
            >
              {{ $t("h4h.continueToCheckout") }}
            </button>
            <div


              class="reject"
              @click="reject"
            >
              {{ $t("h4h.seeOtherOptions") }}
            </div>
          </div>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'HotelRateChangeModal',
  props: {
    room: {
      default: () => {},
      type: Object
    },
    total: {
      type: String,
      default: ''
    }
  },
  methods: {
    reject() {
      this.$emit('reject')
    },
    accept() {
      this.$emit('accept')
    },
  },
}
</script>
