<template>
  <div class="entire-package-box">
    <div class="single-package-select-box">
      <p class="show-price">
        {{ packagePrice(pack) }}
      </p>
    </div>
    <div class="price-show-box" />
    <div class="people-quantity">
      <div class="counter">
        <span
          :class="[disableSubtraction ? 'disable' : '', 'subtract']"
          @click="updateQuantity(-1)"
        />
        <span class="number-of-rooms">
          {{ packageQty }}
        </span>
        <span

          :class="[disableAddition ? 'disable' : '', 'add']"
          @click="updateQuantity(1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { CurrencyApi } from '../../../default/currency_convert'
import { mapMutations } from 'vuex'

export default {

  props:{
    pack:{
      required:true,
      type:Object
    },
  },

  data() {
    return {
      packageQty: 1,
      maxQty:0
    }
  },

  computed: {
    packageInventory() {
      if (this.$store.state.packages.selectedPackage && this.$store.state.packages.selectedPackage.inventory_on_site < 1) {
        return 0
      } else if (this.$store.state.packages.selectedPackage) {
        return Math.min(this.$store.state.packages.selectedPackage.inventory_on_site, 10)
      } else {
        return 1
      }
    },
    quantity() {
      return this.$store.state.packages.packageQty
    },
    order(){
      return this.$store.state.order.order
    },

    currency(){
      if(this.order){
        return this.order.currency
      }else {
        return 'USD'
      }
    },

    rate(){
      if(this.order){
        return this.order.exchange_rate
      }else {
        return 1.0
      }
    },

    disableSubtraction() {
      return this.packageQty < 2
    },

    disableAddition() {
      return this.packageQty==this.packageInventory
    }
  },

  methods: {
    ...mapMutations('packages', [
      'updatePackageQty',
      'updateSelectedPackage'
    ]),
    packageName(pack) {

      if (pack == null) { return }

      return pack.display_name
    },

    packagePrice(pack){

      if (pack.price > 0 && !pack.hide_pricing) {
        return CurrencyApi.formatAmount(pack.price * this.rate, this.currency)
      }
    },

    updateQuantity(value) {
      if(value==1 && this.packageQty >= this.packageInventory){
        null
      }
      else if(value!==-1 || this.packageQty>=2 ){
        if(this.$store.state.packages.selectedPackage.display_name == this.packageName(this.pack)){
          this.packageQty+=value
          this.updatePackageQty(this.packageQty)
        }
      }
    },
    
    selectPackage(){
      this.updateSelectedPackage(this.pack)
      this.packageQty=1
      this.updatePackageQty(1)
    }
  }
}
</script>
