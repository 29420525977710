<template>
  <div class="error-label-container">
    <label
      id="order_item_room_quantity-error"
      class="error room-qty-error"
    >
      {{ $t('roomQtyError') }}
    </label>
  </div>
</template>
