<template>
  <div :class="['cart-footer padding-0', extraclass]">
    <p class="">
      {{ header }}
      <span>{{ headerSubText }}</span>
    </p>
    <p class="">
      {{ amount }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      default: null,
      type: String
    },
    amount: {
      default: null,
      type: String
    },
    headerSubText: {
      default: null,
      type: String
    },
    extraclass: {
      default: null,
      type: String
    }}
}
</script>
