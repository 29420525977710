<template>
  <div class="h4h-form">
    <div v-if="isGpe()">
      <GpeH4hForm />
    </div>
    <div v-else>
      <div
        v-if="packages.length > 0"
        id="addToCartVue"
        class="purchasing_box"
      >
        <h2 class="pricing_header">
          {{ $t('formPackage.packagePricing') }}
          <CurrencyDropdown @currency-emit="updateCurrency" />
        </h2>

        <div id="packageForm">
          <div
            class="heading"
          >
            <label class="occupancy-desc-box">
              Step 1: {{ packages.length > 1 ?
                $t('formPackage.chooseYourPackage') :
                $t('formPackage.chooseYourQuantity') }}

              <i
                v-if="notH4h()"
                class="fa fa-chevron-up"
              />
            </label>
          </div>

          <div class="packages_wrapper">
            <SubPackageInputFields
              v-if="checkForSubpackage()"
              :currency="currency"
              :rate="rate"
              :packages="packages"
            />

            <SoloPackageInputField
              v-else
              :currency="currency"
              :rate="rate"
              :packages="packages"
            />
          </div>

          <!-- <div
            v-if="packageInCart"
            id="packageInCartDisplay"
            class="package-in-cart-display"
          >
            <i>
              <div>
                {{ $t('formPackage.packageInCart', { yourPackage: packageInCartName }) }}
                <span @click="openCart()"> {{ $t('formPackage.viewCart') }} </span>
              </div>
            </i>
            <i
              @click="openCart()"
            />
          </div> -->

          <div class="new_order_item">
            <AddToCart
              :form-btn-text="formBtnText"
              :qtyhotel="hotelQty"
              :qtypackage="packageQty"
              :idpackage="idPackage"
              :hotel-purchasing="hotelPurchasing"
              @order-item-added="redirectToOrderItemHotels"
            />
          </div>
          <PriceInfo
            :currency="currency"
            :rate="rate"
          />

          <span :class="isMotoGP() ? 'tax_disclaimer' : 'tax_disclaimer small'">
            {{ $t('taxDisclaimer') }}
          </span>

          <CurrencyDisclaimer
            :currency="currency"
            :chargeablecurrencies="chargeableCurrencies"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global locale */

import { CurrencyDropdown, CurrencyDisclaimer } from '../../../default/currency_convert'
import AddToCart from './add-to-cart'
import SubPackageInputFields from './subpackage-input-fields'
import SoloPackageInputField from './single-package-input-field'
import PriceInfo from './price-info.vue'
import GpeH4hForm from '../../../full-gpe/h4h_bottom_form/form'


export default {
  name: 'H4hBottomForm',
  components: {
    AddToCart,
    CurrencyDisclaimer,
    CurrencyDropdown,
    GpeH4hForm,
    SubPackageInputFields,
    PriceInfo,
    SoloPackageInputField
  },

  data() {
    return {
      chargeableCurrencies: [],
      currency: 'USD',
      hotelQty: 0,
      packageQty: 0,
      packages: [],
      rate: 1.0,
      packageInCart: false,
      packageInCartName:''
    }
  },

  computed: {
    formBtnText() {
      return this.$t('formPackage.continue')
    },

    idPackage() {
      if (this.$store.state.packages.selectedPackage) {
        return this.$store.state.packages.selectedPackage.id
      } else {
        return null
      }
    },

    hotelPurchasing() {
      if (this.$store.state.packages.selectedPackage) {
        return this.$store.state.packages.selectedPackage.hotel_purchasing
      } else {
        return null
      }
    }
  },

  created() {
    let h4hPackageDiv = document.getElementById('h4hPackageCartFormData')

    if (h4hPackageDiv) {
      this.packages = JSON.parse(h4hPackageDiv.dataset.packages)

      this.chargeableCurrencies = this.packages[0].chargeable_currencies
    }
  },

  methods: {
    updateCurrency(errors, currency, rate) {
      if (currency && rate) {
        this.currency = currency
        this.rate = rate
      }
    },

    openCart() {
      document.getElementById('navCartLink').click()
    },

    checkForSubpackage(){
      return this.packages.length > 1 && this.packages[0].group!=='Package'
    },

    redirectToOrderItemHotels(item) {
      if (item.id) {
        if(item.hotel_purchasing == false || item.hotel_behavior == 'no_hotels') {
          this.openCart()
        } else if(item.hotel_behavior!='no_hotels') {
          window.location.href = `/${locale}/cart/items/${item.id}/hotels`
        } else {
          window.location.href = `/${locale}/cart/checkout`
        }
      }
    },

    isGpe() {
      return (window.brand_name == 'Grand Prix Events')
    },

    isMotoGP() {
      return window.name == 'motogp'
    },

    notH4h() {
      return this.packages[0].not_h4h_cart
    }
  }
}
</script>
