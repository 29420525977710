<template>
  <section
    id="checkoutPayment"
    class="open expandable_section bottom-checkout-column"
  >
    <h2
      class="expandable_header"
    >
      {{ $t("checkout.payment.paymentMethod") }}
    </h2>
    <div v-if="disabledCart">
      <p><b> Processing today: {{ amountDue }} </b></p>
    </div>
    <div class="payment-errors">
      <!-- Form -->
      <div
        v-if="protectGroupMissing"
        @click="revealError('protect-group')"
      >
        <span class="error help is-danger">{{ $t("checkout.payment.selectRefundable") }}</span>
      </div>
      <div
        v-if="roomInformation.length > 0 && reservationNameMissing"
        @click="revealError('hotel')"
      >
        <span class="error help is-danger">{{ $t("confirmation.reservationName") }} is required</span>
      </div>
      <div
        v-if="billingInfoMissing"
        @click="revealError('address')"
      >
        <span class="error help is-danger">{{ missingBillingItem }} is required</span>
      </div>
      <div
        v-if="missingSfAddressField"
        @click="revealError('address')"
      >
        <span class="error help is-danger">There was a problem with the address provided</span>
      </div>
      <div
        v-if="hotelNeeded"
        @click="redirectToHotelIndex()"
      >
        <span class="error">A hotel is required</span>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="coveredByCredit"
        :class="[depositHasPackage ? '' : 'disabled']"
      >
        <div
          class="expandable_content review-payment"
        >
          <p
            id="checkoutError"
            :class="[errs == null ? '' : 'errors']"
            @click="handleError(errs)"
          >
            {{ errs }}
          </p>
        </div>
        <ZeroDollarCheckoutButton
          :buttontext="placeOrderText"
          @place-order="placeOrder(null)"
          @update-terms="updateTerm"
          @update-f1="updateF1"
        />
      </div>
      <div
        v-else
        class="expandable_content review-payment"
        :class="[validToPay ? '' : 'disabled']"
        @click="confirmPayable"
      >
        <!-- eslint-disable vue/no-child-content -->
        <p
          id="checkoutError"
          :class="[errs == null ? '' : 'errors']"
          @click="handleError(errs)"
          v-html="errs"
        />
        <p
          v-if="loginErr"
          id="loginError"
          class="errors"
          @click="retryLogin"
        >
          {{ $t('checkout.payment.preexistingAccount') }}
        </p>

        <StripeForm
          :address="billing.address1"
          :address2="billing.address2"
          :buttontext="placeOrderText"
          :city="billing.city"
          :name="name"
          :phone="billing.phoneNumber"
          :state="billing.state"
          :zipcode="billing.zipCode"
          :valid="validToPay"
          :errs="errs"
          includechecks="true"
          @card-added="placeOrder"
          @update-terms="updateTerm"
          @update-f1="updateF1"
        />
      </div>
      <div
        v-if="card.id != undefined"
        class="processing-payment loading"
      >
        <div class="loading-icon" />

        <button
          disabled
          class="continue-button continue-button btn btn-primary"
        >
          {{ $t("checkout.payment.processing",
                { amount: order.total_decorated, numbers: card.last_4 }) }}
        </button>
      </div>
    </div>
    <div id="app">
      <Modal
        v-show="rateChangeModalDisplayed"
        :room="updatedRoom"
        :total="nightlyRate"
        @reject="rejectRateChange"
        @accept="acceptRateChange"
      />
    </div>
  </section>
</template>

<script>
/* global Turbo */

import Api from '../cart/cart_api'
import CountryData from '../country_data'
import StripeForm from '../stripe_form/credit_card_form.vue'
import ZeroDollarCheckoutButton from './zero_dollar_checkout_button.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import Modal from './confirm_hotel_rate_change'
import CurrencyApi from '../../default/currency_convert/currency-api'

export default {
  name: 'CheckoutPayment',
  components: {
    StripeForm,
    ZeroDollarCheckoutButton,
    Modal
  },

  props: {
    number: {
      default: null,
      required: false,
      type: Number
    },
    failhotel:{
      type: Function,
      default: null
    }
  },

  data() {
    return {
      accepted_terms: false,
      card: {},
      errs: null,
      f1Consent: false,
      sectionName: 'payment',
      loadingContent: false,
      loginErr: false,
      payableErrors: false,
      rateChangeModalDisplayed: false,
      updatedRoom: {},
      nightlyRate: '',
      updatedItemId: 0,
      updateHotelId: 0,
      eventId:0,
      paymentAttempted: false
    }
  },

  computed: {
    ...mapState('checkout', {
      billing: state => state.billing,
      isGuest: state => state.isGuest,
      protectGroupDecisionMade: state => state.protectGroupDecisionMade,
      roomInformation: state => state.roomInformation
    }),
    order(){
      return this.$store.state.order.order
    },
    missingState(){
      return this.$store.state.checkout.addressErrors['state']
    },
    missingZip(){
      return this.$store.state.checkout.addressErrors['zip_code']
    },
    missingCountry(){
      return this.$store.state.checkout.addressErrors['country']
    },
    missingSfAddressField(){
      return this.missingState || this.missingCountry || this.missingZip
    },
    amountDue(){
      let payment_term =  this.$store.state.order.order.payment_terms.filter( term => !term.status || term.status == 'pending')[0]
      if(payment_term.payment_method == 'internal_credit'){
        return CurrencyApi.formatAmount(0, this.order.charge_currency)
      } else {
        return CurrencyApi.formatAmount(payment_term.amount / 100.0, this.order.charge_currency)
      }
    },
    disabledCart(){
      return this.order.disabled_cart
    },
    amountMissing() {
      if(this.order.payment_terms && this.order.discount > 0 && this.order.discount >= this.order.total){
        return false
      } else if( this.order.payment_terms) {
        return this.order.payment_terms[0].amount == undefined ||
             this.order.payment_terms[0].amount == null ||
            (this.order.payment_terms[0].amount < 1 && this.order.credit_amount == 0 && !this.order.zero_dollar_change_order)
      } else {
        return true
      }
    },
    missingBillingItem(){
      let missingItems = []
      this.billing.firstName ? null : missingItems.push('First name')
      this.billing.lastName ? null : missingItems.push('Last name')
      this.billing.phoneNumber ? null : missingItems.push('Phone')
      this.billing.address1 ? null : missingItems.push('Address')
      this.billing.city ? null : missingItems.push('City')
      this.billing.state && this.billing.state != 'missing state value' ? null : missingItems.push('State')
      this.billing.country ? null : missingItems.push('Country')
      this.billing.zipCode ? null : missingItems.push('Zip Code')


      return missingItems.join(', ')
    },
    billingInfoMissing() {
      return jQuery.isEmptyObject(this.billing.firstName) ||
             jQuery.isEmptyObject(this.billing.lastName) ||
             jQuery.isEmptyObject(this.billing.phoneNumber) ||
             jQuery.isEmptyObject(this.billing.address1) ||
             jQuery.isEmptyObject(this.billing.city) ||
             this.billing.state == 'missing state value' ||
             this.invalidCountry ||
             this.invalidPostalCode
    },

    protectGroupMissing() {
      return !this.protectGroupDecisionMade
    },
    reservationNameMissing(){
      let filledInReservationName = this.roomInformation.map(room => {
        if (room['name'] && room['name'].trim() !== ''){
          return true
        } else {
          return false
        }
      })
      return filledInReservationName.includes(false)
    },

    creditsUsed(){
      if(this.order.credit_amount > 0){
        return Math.min(this.order.credit_amount, this.order.total)
      }

      return 0
    },

    hotelNeeded(){
      return this.order.hotel_needed
    },

    coveredByCredit() {
      return (this.order.credit_amount + 100 >= this.order.total || this.order.zero_dollar_change_order)
    },
    firstPaymentTermAmount(){
      if(this.order.zero_dollar_change_order){
        return null
      } else if (this.order.order_type === 'ChangeOrder' || this.order.order_type === 'QuoteOrder'){
        return this.order.amount_due_today
      } else {
        return this.order.payment_terms[0].amount
      }
    },

    formData() {
      return {
        checkout: {
          accepted_terms: this.coveredByCredit ? true : this.accepted_terms,
          address: this.billing.address1,
          address2: this.billing.address2,
          amount: this.firstPaymentTermAmount,
          card_id: this.card.id,
          city: this.billing.city,
          company_name: this.billing.companyName,
          country: this.billing.country,
          paid_by_credits: this.coveredByCredit,
          credits: this.creditsUsed,
          f1_consent: this.f1Consent,
          first_name: this.billing.firstName,
          ga_id: null,
          gcl_id: window.localStorage.gclid,
          last_name: this.billing.lastName,
          order_id: this.order.id,
          phone_number: this.billing.phoneNumber,
          purchase_order_number: this.billing.purchaseOrderNumber,
          room_information: this.$store.state.checkout.roomInformation,
          state: this.billing.state,
          zip_code: this.billing.zipCode
        }
      }
    },

    invalidCountry() {
      return CountryData.blockedCountries().concat(
        ['', null, undefined]).includes(this.billing.country)
    },

    invalidPostalCode() {
      if (['CA', 'US'].includes(this.billing.country)) {
        return jQuery.isEmptyObject(this.billing.zipCode)
      } else {
        // We only require code of US and Canada so empty zip is valid in all other cases
        return false
      }
    },

    name() {
      return `${this.billing.firstName} ${this.billing.lastName}`
    },

    placeOrderText() {
      return this.$t('checkout.payment.placeOrder', { amount: '' })
    },

    valid() {
      return this.card.id == undefined &&
             !this.amountMissing &&
             !this.billingInfoMissing &&
             this.protectGroupDecisionMade
    },

    depositHasPackage(){
      if(this.order && this.order.deposit_item){
        return this.order.items.lengh > 1
      } else {
        return true
      }
    },

    validToPay() {
      if(document.getElementById('admin-logout')) {
        return false
      } else {
        return (!this.amountMissing &&
               !this.billingInfoMissing &&
               this.protectGroupDecisionMade)
      }
    },

    isGuestAccount() {
      let el = document.querySelector('#accountStatus')
      return el.dataset.guest == true
    }
  },

  methods: {
    ...mapActions('checkout', ['updateSection']),
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('checkout', ['updatePaymentAttempted', 'updateAddressErrors', 'updateStripeFormLoading']),
    isEmpty(obj) {
      for(var key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if(obj.hasOwnProperty(key))
          return false
      }
      return true
    },
    formattedAmount() {
      if(this.term.payment_method == 'internal_credit'){
        return CurrencyApi.formatAmount(0, this.currency)
      } else {
        return CurrencyApi.formatAmount(this.term.amount / 100.0, this.currency)
      }
    },
    revealError(target){
      this.updateSection(target)
    },
    redirectToHotelIndex(){
      window.location.href = `/cart/events/${this.order.h4h_event_id}/hotels`
    },
    confirmPayment(paymentTermId, paymentIntentId) {
      let body = JSON.stringify({ checkout: {
        f1_consent: this.f1Consent,
        payment_intent_id: paymentIntentId,
        payment_term_id: paymentTermId,
      }})

      Api.checkoutConfirm(body).then(order => {
        if (order.redirect) {
          Turbo.visit(order.redirect)
        } else if (order.error) {
          this.card = {}
          this.errs = order.error
        } else if (order.hotel_rate_change) {
          this.displayRateChangeModal(order)
        } else {
          this.card = {}
          this.errs = this.$t('checkout.payment.couldNotProcess')
        }
      })
    },
    rejectRateChange() {
      Api.deleteH4hHotelItem(this.updatedItemId, this.updateHotelId, 'DELETE', null).then( () => {
        window.location.href = `/cart/events/${this.eventId}/hotels`
      })
    },
    acceptRateChange(){
      this.rateChangeModalDisplayed = false
      if(this.paymentAttempted){
        this.processPayment()
      } else {
        const body = JSON.stringify({price_change_confirmation_needed: false})
        Api.confirmHotelRateChange(this.updatedItemId, 'PUT', body).then( () => {
          this.processPayment()
          this.rateChangeModalDisplayed = false
        })
      }
    },

    handleError(errs) {
      if(errs == this.$t('checkout.payment.hotelNotAvailable')) {
        location.pathname=`cart/events/${this.order.event_id}/hotels`
      } else{
        errs=null
      }
    },

    handleSCA(order) {
      this.card.stripe.paymentIntentSCA(order.client_secret).then(result => {
        if (result.error) {
          this.card = {}
          this.errs = result.error.message
        } else {
          this.confirmPayment(order.payment_term_id, result.paymentIntent.id)
        }
      })
    },

    placeOrder(card) {
      if (!this.valid) { return }
      card ? this.placeOrderWithCard(card) : this.placeOrderWithCredit()
    },

    async placeOrderWithCredit(){
      if(this.isGuest == 'true') {
        let response = await Api.updateGuestUser(this.$store.state.checkout.emailAddress)
        if (response.ok) {
          this.creditCheckout()
        } else if (!response.ok) {
          this.loginErr = true
        }
      } else {
        this.creditCheckout()
      }
    },

    creditCheckout(){
      Api.checkout(JSON.stringify(this.formData)).then(order => {
        if (order.redirect) {
          window.location.replace(order.redirect)
        } else if (order.client_secret) {
          this.handleSCA(order)
        } else if (order.error) {
          this.errs = order.error
        } else if (order.hotel_rate_change) {
          this.displayRateChangeModal(order)
        }else {
          this.errs = this.$t('checkout.payment.couldNotProcess')
        }
      })
    },

    displayRateChangeModal(order){
      this.updatedRoom = order.room
      this.rateChangeModalDisplayed = true
      this.nightlyRate = order.nightly_rate
      this.updatedItemId = order.item_id
      this.updateHotelId = order.hotel_id
      this.eventId = order.event_id
      this.paymentAttempted = order.payment_attempted
      this.getBackendOrder()
    },
    async placeOrderWithCard(card) {
      this.card = card
      if(this.isGuest == 'true') {
        let response = await Api.updateGuestUser(this.$store.state.checkout.emailAddress)
        if (response.ok) {
          this.updatePaymentAttempted(true)
          this.processPayment()
        } else if (!response.ok) {
          this.card = {}
          this.loginErr = true
        }
      } else {
        this.processPayment()
      }
    },
    confirmPayable(){
      if(!this.valid){
        this.payableErrors = true
      }
    },
    retryLogin() {
      this.updateSection('address')
      this.loginErr = false
    },

    updateF1(bool) {
      this.f1Consent = bool
    },

    updateTerm(bool) {
      this.accepted_terms = bool
    },

    displayAddressErrors(address_errors) {
      this.card = {}
      this.updateStripeFormLoading(false)
      this.updateAddressErrors(address_errors)
    },

    processPayment() {
      Api.checkout(JSON.stringify(this.formData)).then(order => {
        if (order.redirect) {
          window.location.replace(order.redirect)
        } else if (order.client_secret) {
          this.handleSCA(order)
        } else if (order.error) {
          this.$emit('failhotel')
          this.card = {}
          this.errs = order.error
        } else if (order.hotel_rate_change) {
          this.displayRateChangeModal(order)
        } else if (order.address_errors) {
          this.displayAddressErrors(order.address_errors)
        } else {
          this.card = {}
        }
      })
    },
  }
}
</script>
