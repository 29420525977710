<template>
  <div
    v-if="term.payment_method != 'internal_credit'"
    class="checkout-payment-display"
  >
    <p class="amount margin-0 font-size-12">
      {{ formattedAmount }} {{ $t('dueDate', {date: formattedDate}) }}
    </p>
  </div>
</template>

<script>
/* global locale */
import CurrencyApi from '../../../default/currency_convert/currency-api'

export default {

  props: {
    index: {
      default: 0,
      type: Number
    },
    term: {
      default: null,
      type: Object
    }
  },
  computed: {
    dueDate() {
      if (this.term.due_date == undefined) {
        return new Date()
      } else {
        return new Date(this.term.due_date)
      }
    },

    order() {
      return this.$store.state.order.order
    },

    currency(){
      return this.order.charge_currency
    },

    internationalEnDate() {
      return this.dueDate.toLocaleString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      })
    },

    formattedAmount() {
      if(this.term.payment_method == 'internal_credit'){
        return CurrencyApi.formatAmount(0, this.currency)
      } else {
        return CurrencyApi.formatAmount(this.term.amount / 100.0, this.currency)
      }
    },

    formattedDate() {
      if (this.dueDate <= new Date()) {
        return this.$t('today')
      } else {
        if (window.name == 'sportsnet' && (locale == 'en' || locale == 'en-US')) {
          return this.internationalEnDate
        } else {
          return this.dueDate.toLocaleString(locale, {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })
        }
      }
    },
  },
}
</script>
