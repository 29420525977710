<template>
  <div
    class="zero-dollar-checkout-btn"
  >
    <button
      v-if="!processing"
      class="continue-button btn btn-primary"
      @click="placeOrder()"
    >
      {{ buttontext }}
    </button>
    <div
      v-if="processing"
      class="processing-payment loading-card"
    >
      <div class="loading-icon" />

      <button
        disabled
        class="continue-button continue-button btn btn-primary"
      >
        {{ buttontext }}
      </button>
    </div>
  <!-- </div> -->
  </div>
</template>

<script>
export default {
  props: {
    displayerror: {
      default: null,
      type: String,
      required: false
    },
    acceptedTerms: {
      default: false,
      type: Boolean,
      required: false
    },
    f1Consent: {
      default: false,
      type: Boolean,
      required: false
    },
    buttontext: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      displayError: true,
      displayErrorClass: 'hidden',
      processing:false
    }
  },

  computed: {
    displayErrorText() {
      return this.$t('creditCardForm.pleaseAccept')
    },

    disableSubmit() {
      if (this.includechecks == 'true' && this.acceptedTerms == false) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    errs(){
      this.hasErrors()
    },

  },

  methods: {
    placeOrder() {
      if (this.acceptedTerms == false) {
        this.displayError = this.displayErrorText
        this.displayErrorClass = 'card-errors'
        return
      }
      this.processing = true

      this.$emit('place-order')
    }
  }
}
</script>
