<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    v-if="(protectable || quoteOrder) && !zeroDollarChangeOrder"
    id="protectGroupForm"
    class="protect-group-form margin-top-0"
  >
    <div
      class="overflow-auto pointer border-bottom-ccc"
      @click="showMiddleSection()"
    >
      <h3 :class="[highlighted, 'section-header']">
        {{ $t('checkout.protectGroup.refundableBooking') }}
      </h3>
    </div>
    <div
      id="protectGroupContainer"
      class="protectGroupContainer"
    >
      <div class="border-bottom-ccc section-body">
        <div class="protect-group-upgrade-statement">
          <p
            v-html="$t('checkout.protectGroup.upgrade20Statement')"
          />
          <p>
            {{ $t('checkout.protectGroup.weDontRefundCovid') }}
          </p>
          <p
            class="small administered-by"
            v-html="$t('checkout.protectGroup.administeredBy')"
          />
        <!--eslint-enable-->
        </div>
        <div
          :class="['refundable-display-box level mb-0', selectedRefundableOrUnselected]"
          @click="selectRefundable()"
        >
          <div class="recommended">
            {{ $t('checkout.protectGroup.recommended') }}
          </div>
        </div>
        <div
          :class="['refundable-perks-chart', selectedRefundable]"
        >
          <div
            class="refundable-perks-top-line refundable-display-box"
            @click="selectRefundable()"
          >
            <div class="refundable-recommended-bubble level-item level-left">
              <div
                class="protect-group-select-container"
              >
                <div>
                  <i
                    class="protect-group-select refundable"
                  >

                    <i
                      id="refundable-radio"
                      class="fa fa-circle small-protect-group-select refundable"
                    />
                  </i>
                </div>
              </div>
              <div class="refundable-statement">
                <b>{{ $t('checkout.protectGroup.refundableBooking') }}</b>
              </div>
              <div class="protect-group-recommended-bubble-div mx-auto" />
            </div>
            <div
              id="eventProtectGroupPremiumAmount"
              class="event-protect-group-premium-amount level-right"
            >
              {{ protectGroupAmount }}
            </div>
          </div>
          <div class="refundable-perks-chart-row tile">
            <div class="refundable-perks-chart-cell tile is-6">
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
              {{ $t('checkout.protectGroup.unexpectedPersonalProblem') }}
            </div>
            <div class="refundable-perks-chart-cell tile is-6">
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
              {{ $t('checkout.protectGroup.homeEmergency') }}
            </div>
          </div>
          <div class="refundable-perks-chart-row tile">
            <div class="refundable-perks-chart-cell tile is-6">
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
              {{ $t('checkout.protectGroup.publicTransportFailure') }}
            </div>
            <div class="refundable-perks-chart-cell tile is-6">
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
              {{ $t('checkout.protectGroup.relativeSickness') }}
            </div>
          </div>
          <div class="refundable-perks-chart-row tile">
            <div class="refundable-perks-chart-cell tile is-6">
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
              {{ $t('checkout.protectGroup.privateVehicleFailure') }}
            </div>
            <div class="refundable-perks-chart-cell tile is-6">
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
              {{ $t('checkout.protectGroup.preExistingCondition') }}
            </div>
          </div>
          <div class="refundable-perks-chart-row tile">
            <div class="refundable-perks-chart-cell tile is-6">
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
              {{ $t('checkout.protectGroup.emergencyRecall') }}
            </div>
            <div class="refundable-perks-chart-cell tile is-6">
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
              {{ $t('checkout.protectGroup.andManyMore') }}
            </div>
          </div>
          <div class="single-refundable-perks-chart-row tile">
            <div class="refundable-perks-chart-cell">
              <i
                class="fa fa-check"
                aria-hidden="true"
              />
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="$t('checkout.protectGroup.covidRefund')" />
            </div>
            <!-- <div class="refundable-perks-chart-cell tile is-6" /> -->
          </div>
        </div>
        <div
          :class="['nonrefundable-display-box level', selectedNonRefundable]"
          @click="selectNonRefundable()"
        >
          <div>
            <i
              class="protect-group-select"
            >
              <i
                id="non-refundable-radio"
                class="fa fa-circle small-protect-group-select refundable"
              />
            </i>
          </div>
          <label class="nonrefundable-label"> {{ $t('checkout.protectGroup.nonRefundableBooking') }} </label>
        </div>

        <div
          v-if="protectGroupMissing && displayErrors"
        >
          <span class="error help is-danger">{{ $t("checkout.payment.selectRefundable") }}</span>
        </div>
        <div
          class="width-100 flex align-center justify-content-end height-50 margin-top-15"
        >
          <button
            id="saveProtectGroup"
            class="btn btn-secondary"
            @click="proceed"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import CartApi from '../../cart/cart_api'
export default {
  name: 'ProtectGroup',

  props: {
    updateterms: {
      default: null,
      type: String
    }
  },

  data() {
    return {
      displayErrors: false,
      eventProtectable: true,
      packagesProtectable: true,
      quoteOrder: true,
      maxProtectable: 0,
      zeroDollarChangeOrder: false,
      applied: false,
      nonRefundableSelected: false,
      refundableSelected: false,
      unselected: true,
    }
  },
  computed:{
    ...mapState('checkout', {
      protectGroupDecisionMade: state => state.protectGroupDecisionMade
    }),
    selectedRefundableOrUnselected(){
      return this.refundableSelected || this.unselected ? 'selected' : ''
    },
    protectGroupMissing() {
      return !this.protectGroupDecisionMade
    },
    protectGroupCost(){
      if(this.dynamicProtectGroupEnabled){
        return this.$store.state.protect_group.premiumAmount
      } else {
        return this.$store.state.checkout.protectGroupCost
      }
    },
    protectGroupCostInCurrency(){
      if(this.dynamicProtectGroupEnabled){
        return this.$store.state.protect_group.premiumAmount
      } else {
        return this.$store.state.checkout.protectGroupCostChargeCurrency
      }
    },
    protectGroupAmount(){
      if(this.dynamicProtectGroupEnabled){
        return this.$store.state.protect_group.decoratedPremium
      } else {
        return this.order.protect_group_cost_decorated
      }
    },
    dynamicProtectGroupEnabled(){
      return this.$store.state.protect_group.dynamicProtectGroupEnabled
    },
    highlighted(){
      return this.showing ? 'brand-primary' : ''
    },
    selectedRefundable(){
      return this.refundableSelected ? 'selected' : ''
    },
    selectedNonRefundable(){
      return this.nonRefundableSelected ? 'selected' : ''
    },
    showing(){
      return this.$store.state.elements.middleSectionShowing
    },
    protectable(){
      return this.eventProtectable && this.packagesProtectable
    },
    order (){
      return this.$store.state.order.order
    }
  },
  created(){
    CartApi.getProtectGroupInfo().
      then(info => {
        if(info.order_protected){
          this.applied = true
        }
        this.setInitialValues(info)
        this.setProtectGroup(info)
        info.order_protected ? this.selectRefundable() : null
      })
  },
  methods: {
    ...mapMutations('checkout', [
      'customerMadeProtectGroupDecision',
    ]),
    ...mapMutations('elements', [
      'displayMiddleSection',
      'displayBottomSection'
    ]),

    ...mapMutations('order', ['updateOrder', 'updateLoading']),
    ...mapMutations('checkout', [ 'toggleCreditApplication', 'updateCreditAmount']),
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('protect_group', ['updatePremium', 'updateDecoratedPremium',
      'updateDynamicProtectGroupEnabled']),

    updateCreditApplied(){
      if(this.order.customer_total_credit > 0 && this.order.credit_applicable){
        this.updateLoading(true)
        this.updateCreditAmount(this.order.customer_total_credit)
        this.updateOrderInStore(JSON.stringify({ order: { credit_amount: this.$store.state.checkout.creditApplied } }))
      }
    },
    updateOrderInStore(body){
      CartApi.changeOrder(body).then( () => {
        this.getBackendOrder().then( () => this.updateLoading(false) )
      })
    },

    selectRefundable(){
      this.highlightRefundButton()
      this.applied = true
      this.unselected = false
      this.customerMadeProtectGroupDecision()
      this.saveProtectGroup()
    },
    selectNonRefundable(){
      this.highlightNonRefundableButton()
      this.applied = false
      this.unselected = false
      this.customerMadeProtectGroupDecision()
      this.saveProtectGroup()
    },
    highlightRefundButton(){
      this.clearRadios()
      let radio = document.getElementById('refundable-radio')
      this.refundableSelected = true
      this.nonRefundableSelected = false
      radio.classList.add('chosen')
    },
    highlightNonRefundableButton(){
      this.clearRadios()
      let radio = document.getElementById('non-refundable-radio')
      this.nonRefundableSelected = true
      this.refundableSelected = false
      radio.classList.add('chosen')
    },

    saveProtectGroup(){
      if(this.protectGroupDecisionMade){

        if(this.applied){
          this.emitChange(this.protectGroupCostInCurrency)
        } else {
          this.emitChange(0)
        }
      } else {
        this.displayErrors = true
      }
    },
    async emitChange(amount){
      let body = JSON.stringify(
        { order: { protect_group_amount: amount } }
      )
      this.updateLoading(true)
      await CartApi.updateProtectGroup(body).then( order => {
        this.updateOrder(order)
        this.updateCreditApplied()
        this.updateLoading(false)
      })
      this.updateLoading(false)
    },
    setInitialValues(info){
      this.eventProtectable = info.event_pg_enabled
      this.packagesProtectable = info.package_pg_enabled
      this.quoteOrder = info.quote_order
      this.zeroDollarChangeOrder = info.quote_order_hide_protect_group
      this.maxProtectable = info.event_pg_premium
      this.$store.state.checkout.protectGroupPremiumDecorated = info.event_pg_premium_decorated
      this.$store.state.checkout.protectGroupPremium = info.event_pg_premium
      this.$store.state.checkout.protectGroupCost = info.event_pg_premium
      this.$store.state.checkout.protectGroupCostChargeCurrency = info.event_pg_premium_charge_currency
      this.updatePremium(info.dynamic_premium)
      this.updateDecoratedPremium(info.dynamic_premium_decorated)
      this.updateDynamicProtectGroupEnabled(info.dynamic_enabled)
    },

    proceed(){
      this.displayBottomSection()
    },

    showMiddleSection(){
      this.displayMiddleSection()
    },
    setProtectGroup(info){
      if (!info.event_pg_enabled || !info.package_pg_enabled || info.zero_dollar_change_order || info.zero_dollar_quote_order || info.event_pg_premium<=0){
        this.$store.state.checkout.protectGroupDecisionMade = true
      }
    },
    clearRadios() {
      let packagesForm = document.getElementById('protectGroupForm')
      let radios=Array.from(packagesForm.getElementsByClassName('small-protect-group-select'))
      radios.forEach(radio => {
        radio.classList.remove('chosen')
      })
    },
    selectRadioButton(){
      let packagesForm = document.getElementById('multiplePackagesForm')
      packagesForm.getElementsByClassName('small-protect-group-select')[0].classList.add('chosen')
    },
  }
}
</script>
