<template v-if="numberOfAddOns > 0">
  <div class="add-on-item">
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="add-on-item-text"
      v-html="item.cart_text"
    />
    <!--eslint-enable-->
    <div class="add-on-form">
      <PackageFields
        :packages="item.dropdown_items"
        :currency="currency"
        :rate="rate"
        @package-emit="updateSelectedPackage"
      />

      <AddToCart
        :qtyhotel="0"
        :qtypackage="1"
        :idhotel="null"
        :idpackage="selectedPackage.id"
        :idstay="null"
        @order-item-added="refreshAddOns"
      />
    </div>
  </div>
</template>

<script>
import AddToCart from '../add_to_cart_forms/bottom_form/add-to-cart'
import PackageFields from '../add_to_cart_forms/bottom_form/package-input-fields'

export default {
  components: {
    AddToCart,
    PackageFields
  },

  props: {
    item: {
      default: () => {},
      type: Object
    },
    currency: {
      default: 'USD',
      type: String
    },
    rate: {
      default: null,
      type: String
    }
  },

  data() {
    return {
      selectedPackage: null
    }
  },

  watch: {
    item() {
      this.updateSelectedPackage(this.item.dropdown_items[0])
    }
  },

  created() {
    this.updateSelectedPackage(this.item.dropdown_items[0])
  },

  methods: {
    refreshAddOns(item) {
      this.$emit('refresh-add-ons', item)
    },

    updateSelectedPackage(pack) {
      if (pack != null) { this.selectedPackage = pack }
    }
  }
}
</script>
