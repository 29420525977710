<template>
  <div
    v-if="term.payment_method != 'internal_credit'"
    class="checkout-payment-display"
  >
    <p
      class="amount"
    >
      <span class="due-date">
        {{ $t('dueDate', {date: formattedDate}) }}:
      </span>
      <span class="due-amount">
        {{ formattedAmount }}
      </span>
      <!-- {{ index }}. {{ formattedAmount }} {{ $t('dueDate', {date: formattedDate}) }} -->
    </p>
  </div>
</template>

<script>
/* global locale */
import CurrencyApi from '../../../default/currency_convert/currency-api'
import moment from 'moment'

export default {

  props: {

    term: {
      default: null,
      type: Object
    }
  },
  computed: {
    currency (){
      return this.order.charge_currency
    },
    order(){
      return this.$store.state.order.order
    },
    dueDate() {
      if (this.term.due_date == undefined) {
        return new Date()
      } else {

        return moment(this.term.due_date)._d
      }
    },

    formattedAmount() {
      if(this.term.payment_method == 'internal_credit'){
        return CurrencyApi.formatAmount(0, this.currency)
      } else {
        return CurrencyApi.formatAmount(this.term.amount / 100.0, this.currency)
      }
    },

    formattedDate() {
      if (this.dueDate <= new Date() && this.term.status != 'passed') {
        return this.$t('today')
      } else {
        return this.dueDate.toLocaleString(locale, {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        })
      }
    },
  },
}
</script>
