<template>
  <div class="padding-top-5">
    <div
      v-if="conditions.length > 1"
    >
      <PaymentTermDisplay
        v-for="term in conditions"
        :key="term.id"
        :term="term"
      />
    </div>
    <div
      v-else
      class="font-size-12"
    >
      {{ order.total_decorated }}
    </div>
  </div>
</template>

<script>

import PaymentTermDisplay from '../../cart/payment_terms/term_display'
export default {

  components: {
    PaymentTermDisplay
  },

  props: {
    index: {
      default: 0,
      type: Number
    }
  },
  computed: {
    order(){
      return this.$store.state.order.order
    },
    conditions(){
      return this.order.payment_term_options[this.index]
    }
  },
}
</script>
