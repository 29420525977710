<template>
  <select
    id="hotelQuantityDropdown"
    v-model="hotelQty"
    class="select required inline quantity form-control"
    @change="emitQty()"
  >
    <option
      v-for="n in hotelRange"
      :key="n"
      :value="n"
    >
      {{ n }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    hotelMaxQty: {
      default: 1,
      required: true,
      type: Number
    },

    hotelMinQty: {
      default: 1,
      required: true,
      type: Number
    },

    packageQty :{
      default: 1,
      // required: true,
      type: Number
    },

    qty: {
      default: 1,
      required: true,
      type: Number
    }
  },

  data() {
    return {
      hotelQty: 1,
    }
  },

  computed: {
    hotelRange(){
      if(this.packageQty > 1){
        let RoomQtyOptions = []
        for (let i = this.hotelMinQty; i <= this.hotelMaxQty; i++) {
          RoomQtyOptions.push(i)
        }
        return  RoomQtyOptions
      } else {
        let  RoomQtyOptions2 = []
        for (let n = 1; n <= this.hotelMaxQty; n++) {
          RoomQtyOptions2.push(n)
        }
        return  RoomQtyOptions2
      }
    }
  },

  watch: {
    hotelRange(){
      if(this.hotelMinQty > this.hotelQty){
        setTimeout(() => {
          this.$emit('qty-change', this.hotelMinQty)
          document.getElementById('hotelQuantityDropdown').value = this.hotelMinQty
        }, 20)
      }
    },
    qty() {
      this.hotelQty = this.qty
    }
  },

  created() {
    this.$emit('qty-change', this.hotelMinQty)
    this.hotelQty = this.hotelMinQty
  },

  methods: {
    emitQty() {
      this.$emit('qty-change', this.hotelQty)
    }
  }
}
</script>
