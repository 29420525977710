/* global locale */

import Country from './country.js'
import Api from '../local_fetch_call'

export default {
  blockedCountries() {
    return  Country.blockedCountries()
  },

  countryNamesAndCodes() {
    return Country.countryNamesAndCodes()
  },

  countryStates(country_code) {
    return Api.fetchWithoutLocale(`/api/countries/${country_code}.json?locale=${locale}`, 'GET', null).
      then(response => { return response.states })
  }
}
