<template>
  <div v-if="reviews!=0">
    <h4 class="hotel-reviews-title">
      <b>{{ $t('h4h.userReviews') }}</b>
    </h4>
    <div class="hotel-user-ratings">
      <svg
        viewBox="0 0 100 100"
        width="60"
        height="60"
      >
        <path
          v-if="isHof()"
          class="hotel-user-rating"
          fill="#db1d1f"
          stroke="none"
          fill-rule="evenodd"
          :d="d"
        />
        <path
          v-else
          class="hotel-user-rating"
          fill="black"
          stroke="none"
          fill-rule="evenodd"
          :d="d"
        />
        <path
          fill="grey"
          stroke="none"
          fill-opacity="0.4"
          fill-rule="evenodd"
          :d="backgroundD"
          class="hotel-rating"
        />
        <text
          v-if="isHof()"
          class="inner-text"
          x="52"
          y="52"
        >
          {{ rating.toFixed(1) }}
        </text>
        <text
          v-else
          class="inner-text"
          x="50"
          y="50"
        >
          {{ rating.toFixed(1) }}
        </text>
      </svg>
      <p class="reviews">
        {{ reviews }} {{ $t('h4h.reviews') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      type: Number,
      default: null
    },
    reviews: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      opts: {
        cx: 50,
        cy: 50,
        radius: 50,
        start_angle: 0,
        end_angle: 360,
        thickness: 7,
      }
    }
  },

  computed: {
    end_angle() {
      return (360 / 5) * this.rating
    },
    start() {
      return this.mapCartesian(
        this.opts.cx, this.opts.cy, this.opts.radius, this.end_angle
      )
    },
    start2() {
      return this.mapCartesian(
        this.opts.cx, this.opts.cy, this.cutout_radius, this.end_angle
      )
    },
    end() {
      return this.mapCartesian(
        this.opts.cx, this.opts.cy, this.opts.radius, this.opts.start_angle
      )
    },
    end2() {
      return this.mapCartesian(
        this.opts.cx, this.opts.cy, this.cutout_radius, this.opts.start_angle
      )
    },
    largeArcFlag() {
      return this.end_angle-this.opts.start_angle <= 180 ? '0' : '1'
    },
    backgroundArcFlag() {
      return this.end_angle - this.opts.start_angle <= 180 ? '1' : '0'
    },
    cutout_radius() {
      return this.opts.radius - this.opts.thickness
    },
    d() {
      return [
        'M', this.start.x, this.start.y,
        'A', this.opts.radius, this.opts.radius, 0, this.largeArcFlag, 0, this.end.x, this.end.y,
        'L', this.opts.cx, this.opts.cy,
        'Z',

        'M', this.start2.x, this.start2.y,
        'A', this.cutout_radius, this.cutout_radius, 0, this.largeArcFlag, 0, this.end2.x, this.end2.y,
        'L', this.opts.cx, this.opts.cy,
        'Z'
      ].join(' ')
    },
    backgroundD() {
      return [
        'M', this.end.x, this.end.y,
        'A', this.opts.radius, this.opts.radius, 0, this.backgroundArcFlag, 0, this.start.x, this.start.y,
        'L', this.opts.cx, this.opts.cy,
        'Z',

        'M', this.end2.x, this.end2.y,
        'A', this.cutout_radius, this.cutout_radius, 0, this.backgroundArcFlag, 0, this.start2.x, this.start2.y,
        'L', this.opts.cx, this.opts.cy,
        'Z'
      ].join(' ')
    }
  },

  methods:{
    mapCartesian(centerX, centerY, radius, angleInDegrees) {
      if (angleInDegrees == 360) {
        angleInDegrees = 359.9
      }

      let angleInRadians = (angleInDegrees - 90) * Math.PI / 180

      return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
      }
    },
    createSVG() {
      return [
        'M', this.start.x, this.start.y,
        'A', this.opts.radius, this.opts.radius, 0, this.largeArcFlag, 0, this.end.x, this.end.y,
        'L', this.opts.cx, this.opts.cy,
        'Z',

        'M', this.start2.x, this.start2.y,
        'A', this.cutout_radius, this.cutout_radius, 0, this.largeArcFlag, 0, this.end2.x, this.end2.y,
        'L', this.opts.cx, this.opts.cy,
        'Z'
      ].join(' ')
    },
    isHof() {
      return (window.brand_name=='Hall of Fame Experiences')
    }
  }
}
</script>
