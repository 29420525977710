<template>
  <tr>
    <td
      class="padding-twelve"
    >
      <p>{{ hotel.sf_hotel.display_name ? hotel.sf_hotel.display_name : hotel.sf_hotel.name }}</p>
      <em
        v-if="displayCheckInCheckOut"
        class="small"
      > {{ hotel.check_in_and_out_days }} </em>
    </td>

    <!-- <td>
      <span
        v-for="star in stars"
        :key="star"
      >
        <i
          aria-hidden="true"
          class="fa fa-star"
        />
      </span>
    </td> -->
    <td
      class="padding-twelve"
    >
      <span>{{ distance ? distance : '' }}</span>
    </td>
    <td
      v-if="transfers == true"
      class="padding-twelve"
    >
      <span>{{ transfer ? $t('yes') : $t('no') }}</span>
    </td>
    <td class="padding-twelve">
      <a
        :href="url"
        target="_blank"
      >
        {{ $t('viewDetails') }}
      </a>
    </td>
  </tr>
</template>
<script>
import FetchCall from '../../../shared/local_fetch_call'
export default {
  props: {
    hotel: {
      type: Object,
      required: true
    },
    transfers: {
      type: Boolean,
      required: true
    },
    latitude: {
      type: Number,
      required: true
    },
    longitude: {
      type: Number,
      required: true
    },
    displayCheckInCheckOut: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      stars: 1,
      transfer: false,
      url: '/hotel',
      distance: null,
      hotelLatitude: 0,
      hotelLongitude:0
    }
  },
  created(){
    this.updateHotelInfo()
    this.updateARNInfo()
  },
  methods: {
    async updateARNInfo() {
      let res = await FetchCall.fetchWithoutLocale(
        `/api/h4h_hotels/${this.hotel.sf_hotel.arn_hotel_id}`,
        'GET',
        null
      )
      this.hotelLatitude = res.Latitude
      this.hotelLongitude = res.Longitude
      this.stars = parseInt(res.PriceClass[0])
      this.getDirections()
    },
    updateHotelInfo(){
      let transportationFee = parseFloat(this.hotel.sf_hotel.transportation_fee)
      let hotelName = this.hotel.sf_hotel.name.split(' ').join('-').toLowerCase()
      this.transfer = transportationFee > 0
      this.url = `/hotel/${this.hotel.id}#${hotelName}`
    },
    async getDirections(){
      const body=JSON.stringify({
        event_id: this.event.id,
        hotel_sf_id: this.hotel.id
      })

      try {
        let res = await FetchCall.fetchWithoutLocale(
          '/api/h4h_hotels_distance',
          'POST',
          body)
        this.distance = res.distance
      } catch {
        return false
      }
    }
  }
}
</script>
