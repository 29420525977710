<template>
  <div class="order-item-form">
    <div
      id="new_order_item"
      novalidate="novalidate"
      class="simple_form new_order_item"
    >
      <div class="submit">
        <label>
          &#8203;
        </label>
        <div class="form-group">
          <button
            v-if="isXgames()"
            type="submit"
            :disabled="disableButton"
            :class="['btn btn-primary btn-block', disabledClass]"
            name="commit"
            data-ga-event-category="Package-Form"
            data-ga-event-action="Submit"
            @click="createOrderItem"
          >
            <span class="btn-text">{{ formBtnText }}</span>
            <div class="arrow" />
          </button>
          <input
            v-else
            type="submit"
            name="commit"
            data-ga-event-category="Package-Form"
            data-ga-event-action="Submit"
            :value="formBtnText"
            :disabled="disableButton"
            :class="['btn btn-primary btn-block', disabledClass]"
            @click="createOrderItem"
          >
        </div>
      </div>
    </div>
    <div id="app">
      <ClearQuoteCartModal
        v-show="isModalVisible"
        @close="closeModal"
        @confirm="modalConfirmed"
      />
    </div>

    <!-- eslint-disable vue/no-v-html -->
    <div
      :class="['errors', errorClass]"
      v-html="shown_errors"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
import Api from '../../cart/cart_api'
import ClearQuoteCartModal from './clear_quote_cart_modal'
import { mapActions, mapMutations } from 'vuex'

export default {

  components: {
    ClearQuoteCartModal
  },
  props: {
    formBtnText: {
      default: null,
      type: String,
    },
    idhotel: {
      default: null,
      type: Number,
    },
    idpackage: {
      default: null,
      type: Number,
    },
    idpackageoption: {
      default: null,
      type: Number,
    },
    idstay: {
      default: null,
      type: Number,
    },
    qtyhotel: {
      default: 0,
      type: Number,
    },
    qtypackage: {
      default: 0,
      type: Number,
    }},

  data() {
    return {
      disableButton: false,
      errs: null,
      isModalVisible: false
    }
  },

  computed: {
    hotelQty() {
      if (this.idhotel == null) {
        return 0
      } else {
        return this.qtyhotel
      }
    },

    event(){
      return this.$store.state.event
    },
    order(){
      return this.$store.state.order.order
    },
    errorClass(){
      return this.status_error ? 'deposit-error' : ''
    },
    disabledClass(){
      return this.status_error ? 'disabled' : 'not-disabled'
    },
    shown_errors(){
      return this.status_error || this.errs
    },
    status_error(){
      // NEED VERBIAGE
      if(this.event && this.event.status == 'waiting_list_with_deposit_presale' && this.order && this.order.deposit_item == null){
        return 'Please apply your deposit before adding this package to your cart. <a href="/customer">Click here</a> to login and review your deposit.'
      } else {
        return null
      }
    },
    cartDisabled(){
      return this.$store.state.order.order.disabled_cart
    },

    postBody() {
      if(document.getElementById('hotelAbTest')){
        return JSON.stringify({
          order_item: { ...this.abTestBody, ...this.orderItemBody }
        })
      } else if (window.name == 'nba-exp') {
        return JSON.stringify({
          order_item: { ...this.nbaAbTestBody, ...this.orderItemBody }
        })
      } else if (window.name == 'f1'){
        return JSON.stringify({
          order_item: { ...this.f1AbTestBody, ...this.orderItemBody }
        })
      } else {
        return JSON.stringify({
          order_item: this.orderItemBody
        })
      }
    },

    abTestBody(){
      return {
        ab_testing: true
      }
    },

    nbaAbTestBody(){
      return {
        nba_ab_testing: true
      }
    },

    f1AbTestBody(){
      return {
        f1_ab_testing: true
      }
    },

    orderItemBody() {
      return {
        hotel_id: this.idhotel,
        package_id: this.idpackage,
        package_option_id: this.idpackageoption,
        quantity: this.qtypackage,
        room_quantity: this.hotelQty,
        stay_id: this.idstay
      }
    }
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('cart', ['displayCart']),
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
    createOrderItem() {
      if (this.disableButton) { return }

      if(this.cartDisabled) {
        this.showModal()
      } else {
        this.addOrderItem()
      }
    },
    modalConfirmed(){
      this.cartDisabled = false
      this.addOrderItem()
      this.closeModal()
    },
    addOrderItem(){
      this.disableButton = true
      this.errs = null

      Api.createOrderItem(this.postBody).then(response => {
        if (response.id) {
          this.getBackendOrder().then( order => {
            if (order.hotel_needed){
              window.location.href = `/cart/events/${order.h4h_event_id}/hotels`
            } else {
              this.displayCart()
            }
          })
        } else {
          this.errs = response.errors || this.$t('formPackage.error')
        }
        this.disableButton = false
      })
    },
    isXgames() {
      return (window.name == 'x-games')
    },
  }
}
</script>
