<template>
  <section class="open expandable-section">
    <h2
      v-if="order && order.disabled_cart"
      class="expandable_header"
    >
      {{ $t('checkout.address.billingAddress') }}
    </h2>
    <h2
      v-else
      class="expandable_header"
    >
      <!-- @click="proceed(sectionName)" -->
      {{ $t('checkout.address.billingAndShipping') }}
    </h2>

    <span
      class="header-count"
      @click="proceed()"
    />

    <div class="expandable_content old_checkout">
      <div
        class="checkout-address"
      >
        <p
          v-if="!order.disabled_cart"
          class="address-disclaimer"
        >
          {{ $t('checkout.address.disclaimer') }}
        </p>
        <div
          v-if="guestAccount == 'true'"
          id="checkoutEmail"
          class="required-field field"
        >
          <VForm
            id="checkoutLogin"
            :class="[order && order.disabled_cart ? 'disabled-form' : 'section column is-6 pl-0']"
          >
            <Field
              id="checkout_email_address"
              v-model="emailAddress"
              rules="required"
              name="email_address"
              type="email"
              placeholder="*Email Address"
              data-vv-validate-on="input"
              class="input"
              :disabled="paymentAttempted"
            />
            <ErrorMessage
              as="span"
              name="email_address"
              class="error"
            />
            <label
              v-if="querying"
              class="small-label loading"
            >
              <span class="loading-icon" />
              <em>...validating email</em>
            </label>
            <span
              class="error"
            />
            <p
              v-if="blockedEmail"
              id="blockedEmail"
              class="error"
            >
              {{ $t('checkout.address.blockedEmail', { phoneNumber: currentSitePhoneNumber }) }}
            </p>
            <CheckoutLogin
              v-if="(existingEmailAccount && !blockedEmail)"
              :email="emailAddress"
            />
          </VForm>
        </div>
        <VForm
          id="checkoutAddress"
          :class="[order && order.disabled_cart ? 'disabled-form' : 'section column is-6 pl-0']"
        >
          <div class="required-field field">
            <Field
              id="checkout_first_name"
              v-model="firstName"
              name="first_name"
              type="text"
              :placeholder="$t('checkout.address.firstName')"
              rules="required"
              :disabled="blockedEmail"
              @keyup="resetReservation"
            />
            <ErrorMessage
              as="span"
              name="first_name"
              class="error"
            />
          </div>

          <div class="required-field field">
            <Field
              id="checkout_last_name"
              v-model="lastName"
              name="last_name"
              type="text"
              :placeholder="$t('checkout.address.lastName')"
              rules="required"
              :disabled="blockedEmail"
              @keyup="resetReservation"
            />
            <ErrorMessage
              as="span"
              name="last_name"
              class="error"
            />
          </div>

          <div class="corporate-checkbox field">
            <input
              id="checkout-address-corporate"
              v-model="corporate"
              type="checkbox"
              :disabled="blockedEmail"
            >
            <span class="custom-checkbox" />
            <label
              class="checkbox pl-1"
              for="checkout-address-corporate"
            >{{ $t('checkout.address.corporate') }}</label>
          </div>

          <div class="required-field field">
            <Field
              id="checkout_phone_number"
              v-model="phoneNumber"
              name="phone_number"
              type="text"
              :rules="validatePhone"
              :placeholder="$t('checkout.address.phoneNumber')"
              :disabled="blockedEmail"
            />
            <ErrorMessage
              as="span"
              name="phone_number"
              class="error"
            />
          </div>

          <div
            v-if="corporate"
            class="corporate-fields columns mt-2 mx-0 is-justify-content-space-between"
          >
            <input
              v-model="companyName"
              type="text"
              autocomplete="off"
              class="input field column is-5"
              :placeholder="$t('checkout.address.companyName')"
              :disabled="blockedEmail"
            >

            <input
              v-model="purchaseOrderNumber"
              type="text"
              autocomplete="off"
              class="input field column is-5"
              :placeholder="$t('checkout.address.purchaseOrderNumber')"
            >
          </div>

          <div class="required-field field aws-address">
            <Field
              id="checkout_address"
              v-model="address1"
              class="google_address_autocomplete input"
              name="address1"
              type="text"
              :placeholder="$t('checkout.address.address1')"
              rules="required"
              :disabled="blockedEmail"
            />
            <ErrorMessage
              as="span"
              name="address1"
              class="error"
            />
            <span
              v-if="awsSelectionError"
              class="error"
            >{{ awsSelectionError }}</span>
            <div
              v-if="locationSuggestions.length > 0"
              class="location-suggestions"
            >
              <ul class="location-suggestions-ul">
                <li
                  v-for="(place, index) in locationSuggestions"
                  :key="index"
                  class="location-suggestion"
                  @click="selectSuggestedLocation(place)"
                >
                  {{ place.text }}
                </li>
              </ul>
            </div>
          </div>

          <div class="non-required-field field">
            <input
              v-model="address2"
              class="input"
              type="text"
              :placeholder="$t('checkout.address.address2')"
              :disabled="blockedEmail"
            >
          </div>
          <div class="smaller-required-fields field">
            <div class="smaller-required-fields-row">
              <div class="small required-field">
                <Field
                  id="checkout_city"
                  v-model="city"
                  class="input"
                  name="city"
                  type="text"
                  :placeholder="$t('checkout.address.city')"
                  rules="required"
                  :disabled="blockedEmail"
                />
                <ErrorMessage
                  as="span"
                  name="city"
                  class="error"
                />
              </div>

              <div class="small required-field">
                <Field
                  id="checkout_zip_code"
                  v-model="zipCode"
                  class="input"
                  name="zip_code"
                  type="text"
                  :placeholder="$t('checkout.address.zipCode')"
                  :rules="validateZipCode"
                  :disabled="blockedEmail"
                />
                <ErrorMessage
                  as="span"
                  name="zip_code"
                  class="error"
                />
              </div>
            </div>
            <div
              v-if="webOrder"
              class="smaller-required-fields-row"
            >
              <div class="required-field field control">
                <Field
                  id="checkout_country"
                  v-model="country"
                  as="select"
                  name="country"
                  class="select is-multiple"
                  rules="required"
                  :disabled="blockedEmail"
                  @change="updateCountryAndState"
                >
                  <option
                    value=""
                    disabled
                  >
                    {{ $t('checkout.address.country') }}
                  </option>

                  <option
                    v-for="code in preferredCountriesList"
                    :key="code.code"
                    :value="code.code"
                  >
                    {{ code.translations[currentLocale] || code.name }}
                  </option>
                  <hr>

                  <option
                    v-for="selectedCountry in countriesList"
                    :key="selectedCountry.code"
                    :value="selectedCountry.code"
                  >
                    {{ selectedCountry.translations[currentLocale] || selectedCountry.name }}
                  </option>
                </Field>
                <ErrorMessage
                  as="span"
                  name="country"
                  class="error"
                />
              </div>

              <div class="small required-field field control">
                <Field
                  v-if="stateLists.length > 0"
                  id="checkout_state"
                  v-model="state"
                  as="select"
                  name="state"
                  class="select is-multiple"
                  rules="required"
                >
                  <option
                    value="missing state value"
                    disabled
                  >
                    {{ $t('checkout.address.state') }}
                  </option>
                  <option
                    v-for="selectedState in stateLists"
                    :key="selectedState.code"
                    :value="selectedState.code"
                  >
                    {{ selectedState.name != null ? selectedState.name : selectedState.code }}
                  </option>
                </Field>
                <ErrorMessage
                  as="span"
                  name="state"
                  class="error"
                />
              </div>
            </div>

            <div
              v-else
              class="smaller-required-fields-row"
            >
              <div class="required-field field">
                <Field
                  id="checkout_country"
                  v-model="country"
                  name="country"
                  type="text"
                  rules="required"
                  :disabled="blockedEmail"
                />
                <ErrorMessage
                  as="span"
                  name="country"
                  class="error"
                />
              </div>

              <div class="small required-field field">
                <Field
                  id="checkout_state"
                  v-model="state"
                  name="state"
                  type="text"
                  rules="required"
                />
                <ErrorMessage
                  as="span"
                  name="state"
                  class="error"
                />
              </div>
            </div>
          </div>
        </VForm>
      </div>
    </div>
  </section>
</template>

<script>
/* global locale */
import CountryData from '../country_data'
import CheckoutLogin from './checkout_login'
import FetchCall from '../local_fetch_call'
import * as yup from 'yup'
import { mapMutations, mapState } from 'vuex'
import { Field, Form as VForm, ErrorMessage } from 'vee-validate'
import CartApi from '../cart/cart_api'
export default {
  name: 'CheckoutAddress',
  components: {
    CheckoutLogin,
    Field,
    VForm,
    ErrorMessage
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['number', 'updateres'],
  data() {
    return {
      validated: false,
      querying: false,
      emailAddress: '',
      existingEmailAccount: false,
      countriesList: [],
      sectionName: 'address',
      showRequired: false,
      stateLists: [],
      preferredCountry: 'US',
      defaultLocale: '',
      preferredCountries: [],
      preferredCountriesList: [],
      blockedEmail: false,
      locationSuggestions: [],
      awsSelectionError: null,
      previousAddress: ''
    }
  },
  computed: {
    missingState(){
      return this.$store.state.checkout.addressErrors['state']
    },
    missingZip(){
      return this.$store.state.checkout.addressErrors['zip_code']
    },
    webOrder(){
      return this.order.order_type == 'WebOrder'
    },
    missingCountry(){
      return this.$store.state.checkout.addressErrors['country']
    },
    order() {
      return this.$store.state.order.order
    },
    disabledCart() {
      return this.$store.state.order.order.disabled_cart
    },
    ...mapState('checkout', {
      billing: state => state.billing,
      hotelInCart: state => state.hotelInCart,
      isGuest: state => state.isGuest,
      paymentAttempted: state => state.paymentAttempted
    }),
    address1: {
      get() {
        return this.billing.address1
      },
      set(value) {
        this.updateAddress1(value)
      }
    },
    address2: {
      get() {
        return this.billing.address2
      },
      set(value) {
        this.updateAddress2(value)
      }
    },
    city: {
      get() {
        return this.billing.city
      },
      set(value) {
        this.updateCity(value)
      }
    },
    companyName: {
      get() {
        return this.billing.companyName
      },
      set(value) {
        this.updateCompanyName(value)
      }
    },
    corporate: {
      get() {
        return this.billing.corporate
      },
      set(value) {
        this.updateCorporate(value)
      }
    },
    country: {
      get() {
        return this.billing.country
      },
      set(value) {
        if(value){
          this.updateCountry(value)
        }
      }
    },
    firstName: {
      get() {
        return this.billing.firstName
      },
      set(value) {
        this.updateFirstName(value)
      }
    },
    lastName: {
      get() {
        return this.billing.lastName
      },
      set(value) {
        this.updateLastName(value)
      }
    },
    phoneNumber: {
      get() {
        return this.billing.phoneNumber
      },
      set(value) {
        this.updatePhoneNumber(value)
      }
    },
    purchaseOrderNumber: {
      get() {
        return this.billing.purchaseOrderNumber
      },
      set(value) {
        this.updatePurchaseOrderNumber(value)
      }
    },
    state: {
      get() {
        return this.billing.state
      },
      set(value) {
        this.updateState(value)
      }
    },
    zipCode: {
      get() {
        return this.billing.zipCode
      },
      set(value) {
        value && value.trim() == '' ? this.updateZipCode('') : this.updateZipCode(value)
      }
    },
    guestAccount: {
      get() {
        return this.isGuest
      },
      set() {
        let el = document.querySelector('#accountStatus').dataset.guest
        this.updateIsGuest(el)
      }
    },
    blockedCountries() {
      return CountryData.blockedCountries().concat(['', null, undefined]).toString()
    },
    currentLocale() {
      return locale.toLowerCase()
    },
    currentSitePhoneNumber() {
      var location = document.getElementById('country-code').dataset.code
      var phoneNumber = window.phone_numbers[location] || window.phone_numbers['default']
      return phoneNumber
    },
  },
  watch: {
    missingState(){
      this.errors.add({
        field: 'state',
        msg: 'This field is required',
        rule: 'required',
      })
    },
    missingZip(){
      this.errors.add({
        field: 'zip_code',
        msg: 'This field is required and must be less than 20 characters',
        rule: 'required'
      })
    },
    missingCountry(){
      this.errors.add({
        field: 'country',
        msg: 'This field is required',
        rule: 'required',
      })
    },
    country() {
      this.updateCountryAndState()
    },
    emailAddress: function(val) {
      this.updateEmailAddress(val)
      this.isExistingEmail(val)
    },
    disabledCart() {
      if(this.order.disabled_cart){
        this.setValues()
      }
    }
  },
  mounted() {
    let el = document.querySelector('#accountStatus').dataset.guest
    this.updateIsGuest(el)
    this.enableAutocomplete()
    this.isValidated()
  },
  async created() {
    this.countriesList = CountryData.countryNamesAndCodes()
    this.setValues()
  },
  methods: {
    ...mapMutations('checkout', [
      'updateIsGuest',
      'updateAddress1',
      'updateAddress2',
      'updateEmailAddress',
      'updateCity',
      'updateCompanyName',
      'updateCorporate',
      'updateCountry',
      'updateFirstName',
      'updateLastName',
      'updatePhoneNumber',
      'updatePurchaseOrderNumber',
      'updateState',
      'updateZipCode'
    ]),

    validatePhone(phoneValue){
      if(!phoneValue) {
        return 'This field is required'
      }

      if(phoneValue.length < 8){
        return 'Please enter a valid phone number'
      }
      return true
    },

    validateZipCode(){
      if (!this.zipCode) {
        return 'Please provide a zipcode or use 00000 if not applicable'
      }
      return true
    },

    enableAutocomplete() {
      this.enableClickAway()
      let typingTimer = null
      const delay = 250

      Array.from(document.querySelectorAll('.google_address_autocomplete')).forEach(
        element => {
          element.addEventListener('input', (e) => {
            clearTimeout(typingTimer)
            if(this.previousAddress.length > 0){
              typingTimer = setTimeout(() => {
                this.getLocationSuggestions(e.target.value) // Use e.target.value to get the input value
              }, delay)
            }
            this.previousAddress = this.address1
          })
        }
      )
    },

    enableClickAway(){
      document.addEventListener('click', (event) => {
        if(this.locationSuggestions.length <= 0){ return }
        const isClickInside = event.target.closest('.location-suggestions')
        // If the click is outside the locationSuggestionsElement, perform your logic
        if (!isClickInside) {
          this.locationSuggestions = []
        }
      })
    },
    selectSuggestedLocation(place) {
      this.awsSelectionError = null
      CartApi.getLocationById(place.place_id).then((response) => {
        if (response && response.country) {
          this.locationSuggestions = []
          this.address1 = response.street
          this.city = response.city
          this.zipCode = response.zip_code
          this.country = response.country
          this.updateCountryAndState()
          this.state = response.state
        } else {
          this.locationSuggestions = []
          this.awsSelectionError = 'There was an error selecting this address. Please fill out manually'
          // displayError
        }
      })
    },

    getLocationSuggestions(value) {
      CartApi.getLocationSuggestions(value).then((response) => {
        if (response && response.length > 0) {
          this.locationSuggestions = response
        } else {
          this.locationSuggestions = []
        }
      })
    },

    setAddressFields(caseValue, fieldValue = '') {
      switch (caseValue) {
      case 'street_number':
        this.address1 = fieldValue
        break
      case 'route':
        this.address1 += ` ${fieldValue}`
        break
      case 'locality':
        this.city = fieldValue
        break
      case 'postal_code':
        this.zipCode = fieldValue
        break
      case 'country':
        this.country = fieldValue
        break
      case 'administrative_area_level_1':
        this.state = fieldValue
        break
      default:
        break
      }
    },
    resetReservation() {
      // set timer for 1 second
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.UpdateReservation()
      }, 1000)
    },
    UpdateReservation(){
      // after pause of typing for 1 second
      // if the reservation input is not filled out, and we have the first and last name from form
      // select emit action to update reservation on hotel details
      const resName = document.getElementById('reservationName')
      if(this.firstName && this.lastName && resName && resName.value == ''){
        if(resName.value != this.firstName + ' ' + this.lastName) {
          if(Array.from(document.getElementsByClassName('reservation-name'))){
            Array.from(document.getElementsByClassName('reservation-name')).forEach( this.$emit('updateres') )
          }
        }
      }
    },
    setValues() {
      this.firstName = this.order.first_name
      this.lastName = this.order.last_name
      this.corporate = this.order.corporate_group
      this.phoneNumber = this.order.phone
      this.address1 = this.order.street_1
      this.address2 = this.order.street_2
      this.city = this.order.city
      this.state = this.order.state
      this.country = this.order.country
      this.zipCode = this.order.zip_code
      if(this.order.disabled_cart_guest_email){
        this.emailAddress = this.order.disabled_cart_guest_email
      }
      if (this.order.items  && this.order.items.length > 0) {
        if(this.order.items[0].preferred_country) this.preferredCountry = this.order.items[0].preferred_country
        this.defaultLocale = this.order.items[0].site_default_locale
        if (this.defaultLocale == 'en-GB'){
          this.preferredCountries = ['GB', 'US']
        } else {
          this.preferredCountries = ['US']
        }
        if (this.preferredCountry != 'US') this.preferredCountries.push(this.preferredCountry)
      }
      let that = this
      let tempCountriesList = { ...that.countriesList }
      Object.keys(this.countriesList).forEach(function(key){
        if(that.preferredCountries.includes(that.countriesList[key].code)){
          delete that.countriesList[key]
        } else {
          delete tempCountriesList[key]
        }
      })
      this.preferredCountries.forEach(function(key){
        if(tempCountriesList[key]){
          that.preferredCountriesList.push(tempCountriesList[key])
        }
      })
    },
    checkRequiredFields() {
      this.$validator.validate().then(valid => {
        if (valid) {
          // let sectionNext = this.hotelInCart ? 'hotel' : 'review'
          this.proceed()
        } else {
          // Don't continue with invalid fields
          for (let field of this.$validator.fields) { field.update({ events: 'input|blur' }) }
        }
      })
    },
    updateCountryAndState() {
      CountryData.countryStates(this.country).then(states => {
        this.stateLists = (states == undefined ? [] : states)
        let stateWithNoCode = this.stateLists.find(state=>{
          return this.state == state.english_translation
        })
        let stateFound = this.stateLists.find(state=>{
          return this.state == state['code']
        })
        if(this.webOrder){

          if(stateWithNoCode){
            this.state = stateWithNoCode['code']
          } else if(!stateFound){
            this.state = 'missing state value'
          }
          if (this.stateLists.length > 0) {
            this.state = this.state ? this.state : 'missing state value'
          } else {
            this.state = null
          }
        }
        else {
          this.state = this.order.state
        }
        this.$emit('payment-fields-update', { country: this.country, state: this.state })
      })
    },
    proceed() {
      let body = JSON.stringify({
        order: {
          email_address: this.emailAddress,
          first_name: this.firstName,
          last_name: this.lastName,
          corporate_group: this.corporate,
          phone: this.phoneNumber,
          street_1: this.address1,
          street_2: this.address2,
          city: this.city,
          state: this.state,
          country: this.country,
          zip_code: this.zipCode
        }
      })
      FetchCall.fetchWithoutLocale(
        '/cart',
        'PUT',
        body
      )
    },
    apiUserEmail(method, body) {
      this.validating = true
      let tok = document.getElementsByTagName('meta')['csrf-token']
      if (tok) { // csrf-token is null in testing
        tok = tok.getAttribute('content')
      }
      fetch('/customers/customer_email_validations', {
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': tok
        },
        method: method,
        body: body
      }).then((response) => {
        if(response.status == 200) {
          this.existingEmailAccount = true
          this.validated = false
        }
        else if (response.status == 403) {
          this.blockedEmail = true
          this.addsDisabledClassnameToInputs()
        } else if (!response.ok) {
          this.existingEmailAccount = false
          this.validated = true
        }
      })
    },
    addsDisabledClassnameToInputs() {
      setTimeout(() => {
        document.querySelectorAll('input').forEach((e) => { if(e.disabled) {e.classList.add('disabled')} })
      }, '1')
    },
    queryEmailAddress(){
      let schema = yup.string().email()
      let validEmail = schema.isValidSync(this.emailAddress)

      if (validEmail) {
        this.apiUserEmail('POST', JSON.stringify({
          customer: {
            email: this.emailAddress
          }
        }))
      } else {
        this.existingEmailAccount = false
      }
    },
    isExistingEmail() {
      if(!this.querying) {
        this.querying = true
        setTimeout(() => {
          this.queryEmailAddress()
          this.querying = false
        }, 1200)
      }
    },
    isValidated() {
      if(this.isGuest == 'false') {
        this.validated = true
      }
    },
  }
}
</script>
