<template>
  <VForm
    v-if="hotelInCart && !hotelNeeded"
    id="checkoutHotelDetails"
    as="section"
    :class="['open', hideHotel, 'expandable_section section column is-6 pl-0 info-body']"
  >
    <h2
      class="expandable_header"
    >
      {{ $t('checkout.hotelDetails.sectionTitle') }}
    </h2>
    <div class="expandable_content">
      <div class="checkout-hotel">
        <p>{{ $t('checkout.hotelDetails.description') }}</p>
        <!-- Loop begins -->
        <div
          v-for="(room, index) in roomInformation"
          :key="index"
          class="room-information"
        >
          <p>
            <b>{{ $t('checkout.hotelDetails.room', {number: index + 1}) }}</b>
            {{ room.hotelName }}
          </p>
          <p
            v-if="room.checkIn && room.checkOut"
            class="small"
          >
            {{ room.checkIn }} - {{ room.checkOut }}
          </p>
          <!-- v-if="billingFilledOut" -->
          <div class="required-field">
            <Field
              id="reservationName"
              v-model="room.name"
              class="checkout-address reservation-name"
              :name="`name-${index}`"
              type="text"
              placeholder="*Name for Reservation"
              :disabled="sameAsBilling[index]"
              rules="required"
              @keyup="checkRequiredFields"
            />
            <ErrorMessage
              as="span"
              :name="`name-${index}`"
              class="error"
            />
          </div>
        </div>
        <!-- Loop Ends -->
      </div>
    </div>
  </VForm>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { Form as VForm, Field, ErrorMessage } from 'vee-validate'
import FetchCall from '../local_fetch_call'
export default {
  components: {
    Field,
    VForm,
    ErrorMessage
  },

  props: {
    number: {
      default: null,
      required: false,
      type: Number
    }
  },
  data() {
    return {
      // orderItems: [],
      // reservations: [],
      sectionName: 'hotel',
      sectionNext: 'review',
    }
  },
  computed: {
    ...mapState('checkout', {
      billing: state => state.billing,
      section: state => state.section,
      hotelInCart: state => state.hotelInCart
    }),
    hotelNeeded(){
      return this.order.hotel_needed
    },
    selected() {
      return this.section == this.sectionName
    },
    hideHotel(){
      return this.hotelInCart ? '' : 'invisible'
    },
    billingFirstName(){
      return this.$store.state.checkout.billing['firstName']
    },
    billingLastName() {
      return this.$store.state.checkout.billing['lastName']
    },
    billingFilledOut() {
      return (this.billingFirstName && this.billingLastName ? true : false)
    },
    reservations(){
      return this.$store.state.checkout.reservations
    },
    roomInformation(){
      return this.$store.state.checkout.roomInformation
    },
    sameAsBilling(){
      return this.$store.state.checkout.sameAsBilling
    },
    order() {
      return this.$store.state.order.order
    },
    orderItems () {
      return this.$store.state.order.order.items
    }
  },
  watch: {
    sameAsBilling() {
      this.updateReservationName()
    },
    orderItems(){
      this.getOrderReservations()
    }
  },
  methods: {
    ...mapMutations('checkout', [
      'setReservations',
      'setRoomInfo',
      'updateHotelInCart'
    ]),
    ...mapActions('checkout', ['updateReservationInfo']),
    getRoomInformation() {
      if(!this.orderItems || this.orderItems.length === 0) return

      this.orderItems.forEach(item => {
        this.setRoomInfo(item)
      })
      this.roomInformation.length > 0 ?
        this.updateHotelInCart(true) :
        this.updateHotelInCart(false)
    },
    async getOrderReservations() {
      let res = await FetchCall.fetchWithoutLocale(
        '/api/checkout_hotel_reservations',
        'GET',
        null
      )
      if (!res.reservations) return
      this.setReservations(res.reservations)
      this.getRoomInformation()
    },
    updateReservationName() {
      for (let i = 0; i < this.sameAsBilling.length; i ++) {
        if (this.sameAsBilling[i] && this.billing.firstName && this.billing.lastName) {
          let name = `${this.billing.firstName} ${this.billing.lastName}`
          this.roomInformation[i].name = name
        }
      }
      this.checkRequiredFields()
    },
    updateReservationFromAddress(){
      this.$store.state.checkout.roomInformation.forEach(room => {
        room.name = this.$store.state.checkout.billing.firstName + ' ' + this.$store.state.checkout.billing.lastName
      })
      this.checkRequiredFields()
    },
    async checkRequiredFields() {
      this.updateReservationInfo(this.sectionNext)
    }
  }
}
</script>
