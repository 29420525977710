<template>
  <div class="money-info">
    <h4 class="subtotal">
      {{ $t("formPackage.subtotal") }}

      <currency-convert
        :amount="subtotal"
        :currency="currency"
      />
    </h4>
    <div v-if="hasPaymentTerms">
      <h4 class="or-payment-terms">
        {{ $t("formPackage.paymentCount", { count: maxPaymentTermOptions }) }}

        <currency-convert
          :amount="lowestPaymentSubtotal"
          :currency="currency"
        />
      </h4>

      <p class="or-payment-terms">
        <small>
          {{ dueDatesText }}
        </small>
      </p>
    </div>
  </div>
</template>

<script>
/* global locale */

import Fraction from 'fraction.js'
import { CurrencyConvert } from '../../../default/currency_convert'

export default {
  components: {
    CurrencyConvert
  },

  props: {

    hotel: {
      default: () => {},
      type: Object,
    },

    hotelQty: {
      default: 0,
      required: true,
      type: Number
    },

    pack: {
      default: () => {},
      type: Object
    },

    packageQty: {
      default: 0,
      required: true,
      type: Number
    },


    stay: {
      default: () => {},
      type: Object
    }
  },

  data() {
    return {
      maxPaymentTermOptions: 0,
      paymentTerms: []
    }
  },

  computed: {
    discount() {
      if (this.pack != null && this.pack.apply_discount) {
        return this.pack.discount * this.packageQty * this.rate
      } else {
        return 0
      }
    },

    order(){
      return this.$store.state.order.order
    },

    currency(){
      if(this.order){
        return this.order.currency
      }else {
        return 'USD'
      }
    },

    rate(){
      if(this.order){
        return this.order.exchange_rate
      }else {
        return 1.0
      }
    },

    dueDates() {
      let dates = []

      this.paymentTerms.forEach(term => {
        if (term.paymentCountGroup == this.maxPaymentTermOptions && term.dueDate > new Date()) {
          dates.push(this.dateText(term.dueDate))
        }
      })

      dates.sort( (a,b)=> {
        return new Date(a) - new Date(b)
      })

      return dates
    },

    dueDatesText() {
      if (this.dueDates.length > 1) {
        return this.$t('formPackage.dueDateTextMultiple',
          { datesList: this.dueDates.join(' & on ') })
      } else {
        return this.$t('formPackage.dueDateTextTwo', { date: this.dueDates.join('') })
      }
    },

    hasPaymentTerms() {
      return this.maxPaymentTermOptions >= 1 && !this.pack.x100_payment_term_required
    },

    lowestPaymentSubtotal() {
      return (this.subtotal / this.maxPaymentTermOptions)
    },

    subtotal() {
      if (this.pack == null) { return 0 }

      let subtotal = this.pack.price * this.packageQty

      if (this.stay != null) {
        subtotal += (this.stay.price * this.hotelQty) + this.transportationFee
      } else if (this.hotel != null) {
        subtotal += (this.hotel.price * this.hotelQty) + this.transportationFee
      }
      return subtotal * this.rate
    },

    transportationFee() {
      if (this.hotel != null && this.pack.include_transportation) {
        return this.hotel.transportation_fee * this.packageQty
      } else {
        return 0
      }
    },
  },

  watch: {
    pack() {
      this.setPaymentTerms()
    }
  },

  created() {
    this.setPaymentTerms()
  },

  methods: {
    dateText(date) {
      if (locale == 'en-GB' || this.isSportsnetAndEnglish()) {
        return date.toLocaleDateString(
          'en-GB',
          { day: 'numeric', month: 'long', year: 'numeric' }
        )
      } else {
        return date.toLocaleDateString(
          locale,
          { month: 'long', day: 'numeric', year: 'numeric' }
        )
      }
    },

    setPaymentTerms() {
      this.maxPaymentTermOptions = 0
      this.paymentTerms = []

      if (this.pack == null) { return }

      this.pack.event_payment_terms.forEach(term => {
        // filter out bad dates
        if (new Date(term.start_date) <= new Date() && new Date() < new Date(term.end_date)) {
          let updatedTerm = term
          updatedTerm.paymentCountGroup = new Fraction(term.payment_type).d
          let estDate = new Date(term.due_date)
          let estOffset = 5 * 60 // EST offset is 5 hours
          let estTime = estDate.getTime() + (estOffset * 60 * 1000) // Adjusting for EST offset
          updatedTerm.dueDate = new Date(estTime)
          this.maxPaymentTermOptions = Math.max(this.maxPaymentTermOptions, updatedTerm.paymentCountGroup)
          this.paymentTerms.push(updatedTerm)
        }
      })
    },
    isSportsnetAndEnglish() {
      return window.name == 'sportsnet' && (locale == 'en' || locale == 'en-US')
    }
  }
}
</script>
