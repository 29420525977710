<template>
  <div class="new_order_item package-and-quantity">
    <div class="package-select">
      <div class="form-group select optional inline">
        <label class="select optional control-label">
          {{ $t('formPackage.package') }}
        </label>

        <select
          id="vue_package_id"
          v-model="selectedPackage"
          :class="[packages.length < 2 ? 'single_item' : '', 'select', 'optional', 'form-control']"
          @change="resetPackageQty()"
        >
          <option
            v-for="pack in packages"
            :key="pack.id"
            :value="pack"
            v-text="packText(pack)"
          />
        </select>
      </div>
    </div>

    <div class="quantity-select">
      <div class="form-group select required inline">
        <label>
          {{ packageInventoryText }}
        </label>

        <h6
          v-if="staticQuantity"
          class="deposit-qty"
        >
          {{ maxDepositQty }}
        </h6>

        <select
          v-else-if="qtyRequirementExists"
          id="bottom_form_customer_qty"
          v-model="packageQty"
          class="select required inline quantity form-control"
          @change="emitPackageQty()"
        >
          <option
            v-for="n in requiredQtyPossibilities"
            :key="n"
            :value="n"
          >
            {{ n }}
          </option>
        </select>

        <select
          v-else
          id="bottom_form_customer_qty"
          v-model="packageQty"
          :disabled="ten_required"
          class="select required inline quantity form-control"
          @change="emitPackageQty()"
        >
          <option
            v-for="n in packageInventory"
            :key="n"
            :value="n"
          >
            {{ n }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { CurrencyApi } from '../../../default/currency_convert'

export default {

  props: {
    packages: {
      default: () => [],
      required: true,
      type: Array
    },
  },

  data() {
    return {
      packageQty: 1,
      selectedPackage: null,
      requiredQtyPossibilities: []
    }
  },

  computed: {
    packageInventory() {
      if (this.selectedPackage && this.selectedPackage.inventory_on_site < 1) {
        return 0
      } else if (this.selectedPackage && this.selectedPackage.hotel_required == true) {
        return Math.min(this.selectedPackage.inventory_on_site, this.maxAllowedByHotel, 10)
      } else if (this.selectedPackage) {
        return Math.min(this.selectedPackage.inventory_on_site, this.maxDepositQty, 10)
      } else {
        return 1
      }
    },

    maxAllowedByHotel() {
      return (this.$store.state.packages.hotelMaxInventory * 3)
    },

    order(){
      return this.$store.state.order.order
    },

    currency(){
      if(this.order){
        return this.order.currency
      }else {
        return 'USD'
      }
    },

    staticQuantity(){
      return this.order && this.order.deposit_qty_exact_match
    },

    ten_required() {
      return this.selectedPackage && this.selectedPackage.ten_required == true
    },

    qtyRequirementExists(){
      return this.selectedPackage && this.selectedPackage.quantity_required > 1
    },

    qtyRequired(){
      return this.selectedPackage.quantity_required
    },

    maxDepositQty(){
      if(this.order && this.order.max_deposit_package_quantity){
        if(this.selectedPackage.inventory_on_site >= this.order.max_deposit_package_quantity ){
          return this.order.max_deposit_package_quantity
        }
        else {
          return 'Insufficient Inventory'
        }
      }else {
        return 10
      }
    },

    rate(){
      if(this.order){
        return this.order.exchange_rate
      }else {
        return 1.0
      }
    },

    packageInventoryText() {
      return this.$t('formPackage.numberOfPeople')
    },

    maxPackageQty() {
      return Math.min(this.selectedPackage.inventory_on_site, 10)
    },
  },

  watch: {
    packages() {
      this.refreshPackages()
    },
    staticQuantity() {
      if(this.staticQuantity){
        this.packageQty = this.order.max_deposit_package_quantity
        this.emitPackageQty()
      }
    }
  },

  created() {
    //eslint-disable-next-line vue/no-mutating-props
    this.packages.sort(this.compareByPosition)
    this.refreshPackages()
  },

  methods: {
    emitPackageQty() {
      this.$emit('qty-emit', this.packageQty)
    },

    updateRequiredPkgQty(){
      this.packageQty = this.qtyRequired
      this.emitPackageQty()
      this.requiredQtyPossibilities = []
      for (let i = this.qtyRequired; i <= this.packageInventory; i += this.qtyRequired) {
        this.requiredQtyPossibilities.push(i)
      }
    },

    packText(pack) {
      if (pack == null) { return }

      let name = pack.display_name

      if (pack.price > 0 && !pack.hide_pricing) {
        name = name + ' (' + CurrencyApi.formatAmount(pack.price * this.rate, this.currency) + ')'
      }

      return name
    },

    compareByPosition(a, b){
      if (a.position > b.position) { return 1 }
      if (b.position > a.position) { return -1 }
    },

    refreshPackages() {
      this.selectedPackage = this.packages[0]
      this.setTen_required()
      this.resetPackageQty()
    },

    setTen_required(){
      if(this.selectedPackage.ten_required == true){
        this.packageQty = 10
        this.emitPackageQty()
      }
    },

    resetPackageQty() {
      this.$emit('package-emit', this.selectedPackage)
      if(this.qtyRequirementExists){
        this.updateRequiredPkgQty()
      }
      if (this.selectedPackage.inventory_on_site < this.qtyPackage) {
        this.packageQty = 1
        this.emitPackageQty()
      }
    }
  }
}
</script>
