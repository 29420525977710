<template>
  <div
    id="cont-button"
    class="order-item-form"
  >
    <div
      id="new_order_item"
      novalidate="novalidate"
      class="simple_form new_order_item"
    >
      <div class="submit">
        <label>
          &#8203;
        </label>
        <div class="form-group">
          <input
            type="submit"
            name="commit"
            data-ga-event-category="Package-Form"
            data-ga-event-action="Submit"
            :value="formBtnText"
            :disabled="disableButton"
            class="btn btn-primary btn-block"
            @click="createOrderItem"
          >
        </div>
      </div>
    </div>

    <div class="errors">
      {{ errs }}
    </div>
  </div>
</template>

<script>

import Api from '../../cart/cart_api'
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    'formBtnText': {
      default: 'Continue',
      type: String
    },
    'idpackage': {
      default: 0,
      type: Number
    },
    'qtyhotel': {
      default: 0,
      type: Number
    },
    'qtypackage': {
      default: 0,
      type:Number
    },
    'hotelPurchasing': {
      default: null,
      type: Boolean
    }
  },

  data() {
    return {
      disableButton: false,
      errs: null
    }
  },

  computed: {
    hotelQty() {
      if (this.idhotel == null) {
        return 0
      } else {
        return this.qtyhotel
      }
    },

    postBody() {
      if(document.getElementById('hotelAbTest')){
        return JSON.stringify({
          order_item: { ...this.abTestBody, ...this.orderItemBody }
        })
      } else {
        return JSON.stringify({
          order_item: this.orderItemBody
        })
      }
    },

    abTestBody(){
      return {
        ab_testing: true
      }
    },

    orderItemBody() {
      return {
        hotel_id: this.idhotel,
        package_id: this.idpackage,
        quantity: this.$store.state.packages.packageQty,
        room_quantity: this.hotelQty,
        stay_id: this.idstay,
        hotel_purchasing: this.hotelPurchasing
      }
    }
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('cart', ['displayCart']),
    createOrderItem() {
      if (this.disableButton) { return }

      this.disableButton = true
      this.errs = null

      Api.createOrderItem(this.postBody).then(response => {
        if (response.id) {
          this.getBackendOrder().then( order =>{
            if(order.id){ this.displayCart() }
          })
        } else {
          this.errs = response.errors || this.$t('formPackage.error')
        }
        this.disableButton = false
      })
    }
  }
}
</script>
