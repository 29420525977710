<template>
  <div class="hotel-index-container">
    <div class="row hotel-detail">
      <div class="hotel-banner">
        <div>
          <h2>
            {{ hotel.name }}

            <small class="hotel-stars">
              <i
                v-for="star in stars"
                :key="star.index"
                class="ionicon star-icon fa fa-star"
                :style="{ display: 'none' }"
              />
            </small>
          </h2>
          <p class="contract-featured-bubble">
            {{ featuredText }}
          </p>
          <p v-if="transportationProvided">
            <em class="small">One-time transportation to and from the event venue and hotel on days included with purchase</em>
          </p>
        </div>
      </div>
      <div class="padding-top-20">
        <p>{{ hotelAddress }}</p>
        <p v-if="distance">
          {{ distance }} to {{ event.venue }}
        </p>
      </div>
    </div>
    <ImageCarousel
      v-if="arnHotel.images.length > 0"
      :images="arnHotel.images"
    />

    <div class="checkin-info-amenities-container container">
      <div class="col-sm-9 contracted checkin-info">
        <div class="room-information">
          <div
            v-for="stay in stays"
            :key="stay"
            class="contract-room-card-container"
          >
            <h3>{{ stay.room_type }}</h3>
            <p>{{ stay.description }}</p>
          </div>
        </div>
        <div class="property-description-title margin-top-40">
          <h4><strong>Property Description</strong></h4>
          <p>{{ arnHotel.description }}</p>
        </div>
      </div>

      <div
        class="hotel-info-amenities-container container"
      >
        <div class="hotel-side-container">
          <div class="hotel-side-container-child">
            <HotelAmenities
              :amenities="arnHotel.amenities"
            />
            <Rating
              :rating="arnHotel.rating"
              :reviews="arnHotel.reviews"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ImageCarousel from '../add_to_cart_forms/h4h_rooms/hotel_images'
import HotelAmenities from '../add_to_cart_forms/h4h_rooms/hotel_amenities'
import Rating from '../add_to_cart_forms/h4h_rooms/hotel_rating'
// import H4hMap from '../add_to_cart_forms/h4h_rooms/maps'
import FetchCall from '../local_fetch_call'


export default {
  components: {
    ImageCarousel,
    HotelAmenities,
    Rating,
    // H4hMap,
  },

  data() {
    return {
      hotel: {},
      arnHotel:{},
      event: {},
      stays: [],
      stars: 0,
      distance: null
    }},

  computed:{
    hotelAddress(){
      return (this.arnHotel.address.toLowerCase() + ', ' + this.arnHotel.zip + ' ' +
                this.arnHotel.city.toLowerCase() + ' ' + this.arnHotel.state.toLowerCase() +
                ' ' + this.arnHotel.countryCode.toLowerCase())
    },
    featuredText(){
      if(parseFloat(this.hotel.transportation_fee) > 0){
        return 'FEATURED: WITH TRANSPORTATION'
      } else {
        return 'FEATURED'
      }
    },
    transportationProvided(){
      return this.hotel.transportation_fee > 0
    }
  },

  created(){
    this.setHotelInfo()
  },

  methods: {
    setHotelInfo() {
      let hotel = document.getElementById('contractedHotelOverview').dataset.hotel
      let arnHotel = document.getElementById('contractedHotelOverview').dataset.arnHotel
      let stays = document.getElementById('contractedHotelOverview').dataset.stays
      let event = document.getElementById('contractedHotelOverview').dataset.event
      this.hotel = JSON.parse(hotel)
      this.arnHotel = JSON.parse(arnHotel)
      this.stays = JSON.parse(stays)
      this.event = JSON.parse(event)
      this.stars = parseInt(this.arnHotel.class[0])

      this.getDirections()
    },
    async getDirections(){
      // need to figure out where this is being called and update
      const body=JSON.stringify({
        event_id: this.event.id,
        hotel_sf_id: this.hotel.id
      })

      try {
        let res = await FetchCall.fetchWithoutLocale(
          '/api/h4h_hotels_distance',
          'POST',
          body)
        this.distance = res.distance
      } catch {
        return false
      }
    },
  }
}
</script>
