<template>
  <div
    class="entire-package-box row"
    @click="selectPackage()"
  >
    <!-- <input
      class="hidden-radio-button"
      type="radio"
    > -->
    <div class="radio-select-container">
      <i
        :id="'vue_package_id-' + pack.id"
        class="fa fa-circle"
        :data-id="pack.id"
        :value="pack"
      />
    </div>
    <div class="price-and-package">
      <div
        class="package-select-box"
        @click="selectPackage"
      >
        <label
          v-text="packageName(pack)"
        />
      </div>
      <div class="price-show-box">
        <p
          class="show-price"
        >
          {{ packagePrice(pack) }}
        </p>
      </div>
    </div>
    <div
      class="people-quantity"
    >
      <div class="counter">
        <span
          :class="[disableSubtraction ? 'disable' : '', 'subtract']"
          @click="updateQuantity(-1)"
        />
        <span class="number-of-rooms">
          {{ showPackageQty() }}
        </span>
        <span
          :class="[disableAddition ? 'disable' : '', 'add']"
          @click="updateQuantity(1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { CurrencyApi } from '../../../default/currency_convert'
import { mapMutations } from 'vuex'

export default {

  props:{
    pack:{
      required:true,
      type:Object
    },
    currency: {
      default: 'USD',
      required: true,
      type: String
    },
    rate: {
      default: 1.0,
      required: true,
      type: Number
    }
  },

  data() {
    return {
      packageQty: 1,
      maxQty:0
    }
  },

  computed: {
    packageInventory() {
      if (this.$store.state.packages.selectedPackage && this.$store.state.packages.selectedPackage.inventory_on_site < 1) {
        return 0
      } else if (this.$store.state.packages.selectedPackage) {
        return Math.min(this.$store.state.packages.selectedPackage.inventory_on_site, 10)
      } else {
        return 1
      }
    },
    quantity() {
      return this.$store.state.packages.packageQty
    },

    disableSubtraction() {
      return this.packageQty < 2
    },

    disableAddition() {
      return this.checkPackEquality() && this.packageQty==this.packageInventory
    }
  },

  mounted(){
    this.selectRadioButton()
  },

  methods: {
    ...mapMutations('packages', [
      'updateSelectedPackage',
      'updatePackageQty'
    ]),
    checkPackEquality(){
      return this.pack == this.$store.state.packages.selectedPackage
    },
    clearRadios(){
      let packagesForm = document.getElementById('multiplePackagesForm')
      let radios=Array.from(packagesForm.getElementsByClassName('fa-circle'))
      radios.forEach(radio => {
        radio.classList.remove('chosen')
      })
    },
    packageName(pack) {
      if (pack == null) { return }

      return pack.display_name
    },

    packagePrice(pack){
      if (pack.price > 0 && !pack.hide_pricing) {
        return CurrencyApi.formatAmount(pack.price * this.rate, this.currency)
      }
    },
    selectPackage(){
      if(this.pack!=this.$store.state.packages.selectedPackage){
        this.updateSelectedPackage(this.pack)
      }
      this.updatePackageQty(this.packageQty)
      this.updateRadioButton()
    },
    selectRadioButton(){
      let packagesForm = document.getElementById('multiplePackagesForm')
      packagesForm.getElementsByClassName('fa-circle')[0].classList.add('chosen')
    },
    showPackageQty(){
      if(this.pack===this.$store.state.packages.selectedPackage && this.pack.inventory_on_site>0){
        return this.packageQty
      } else {
        return 0
      }
    },
    updateRadioButton(){
      this.clearRadios()
      let radio = document.getElementById('vue_package_id-' + this.pack.id)
      if(this.$store.state.packages.selectedPackage.id === this.pack.id){
        radio.classList.add('chosen')
      }
    },

    updateQuantity(value) {
      if(value==1 && this.packageQty >= this.packageInventory){
        null
      }
      else if(value!==-1 || this.packageQty>=2 ){
        if(this.$store.state.packages.selectedPackage.display_name == this.packageName(this.pack)){
          this.packageQty+=value
          this.updatePackageQty(this.packageQty)
        }
      }
    }
  }
}
</script>
