<template>
  <div
    id="multiplePackagesForm"
    class="new_order_item all-packages"
  >
    <div
      class="package-select-row"
    >
      <label
        class="select optional control-label package-pricing-label"
      >
        {{ $t('formPackage.package') }}
      </label>
      <label
        class="select optional control-label price-pricing-label"
      >
        Price
      </label>
      <label
        class="select optional control-label num-of-people-pricing-label"
      >
        {{ packageInventoryText }}
      </label>
    </div>

    <PackageItem
      v-for="pack in packages"
      :key="pack.id"
      :pack="pack"
      :rate="rate"
      :currency="currency"
      :packages="packages"
    />
  </div>
</template>

<script>
import { CurrencyApi } from '../../../default/currency_convert'
import PackageItem from './package-item'
import { mapMutations } from 'vuex'


export default {
  components: {
    PackageItem,
  },
  props: {
    packages: {
      default: new Array(),
      required: true,
      type: Array
    },

    currency: {
      default: 'USD',
      required: true,
      type: String
    },

    rate: {
      default: 1.0,
      required: true,
      type: Number
    }
  },

  computed: {
    packageInventoryText() {
      return this.$t('formPackage.numberOfPeople')
    }
  },

  created() {
    //eslint-disable-next-line vue/no-mutating-props
    this.packages.sort(this.compareByPosition)
    this.refreshPackages(this.packages)
  },

  methods: {
    ...mapMutations('packages', [
      'refreshPackages'
    ]),
    packageName(pack) {
      if (pack == null) { return }

      return pack.display_name
    },

    packagePrice(pack){

      if (pack.price > 0 && !pack.hide_pricing) {
        return CurrencyApi.formatAmount(pack.price * this.rate, this.currency)
      }
    },

    compareByPosition(a, b){
      if (a.position > b.position) { return 1 }
      if (b.position > a.position) { return -1 }
    },
  }
}
</script>
