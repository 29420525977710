<template>
  <div
    id="checkoutVue"
    class="checkout-vue-form stripe-elements flex flex-row container"
    :class="[orderLoading ? 'loading' : '' ,'cart']"
  >
    <div class="loading-icon" />
    <!-- <section
        is="vue:Cart"
      />
      <section
        is="vue:AddOns"
      /> -->
    <div class="left sixty left-column background-fff padding-bottom-20">
      <h1>{{ $t('h4h.checkoutStep') }}</h1>
      <AddressAndHotel />

      <ProtectGroup />

      <PaymentSection />


      <!-- </div> -->
      <!-- </div> -->
      <!-- <section
        is="vue:CheckoutPayment"
        @failhotel="failHotel()"
      /> -->
    </div>
    <div id="app">
      <HotelRateChangeModal
        v-show="rateChangeModalDisplayed"
        :room="updatedRoom"
        :total="nightlyRate"
        @reject="rejectRateChange"
        @accept="acceptRateChange"
      />
    </div>
    <CheckoutCart />
  </div>
</template>

<script>

import AddressAndHotel from './address_and_hotel'
import CartApi from '../../cart/cart_api'
import PaymentSection from './payment_section'
import ProtectGroup from './protect_group.vue'
import CheckoutCart from './checkout_cart.vue'
import HotelRateChangeModal from '../confirm_hotel_rate_change'

import { mapMutations, mapGetters, mapState, mapActions} from 'vuex'
export default {
  components: {
    AddressAndHotel,
    PaymentSection,
    CheckoutCart,
    HotelRateChangeModal,
    ProtectGroup,
  },
  data() {
    return {
      currentSection: 'none',
      ProtectGroup: ProtectGroup,
      CheckoutCart: CheckoutCart,
      PaymentSection: PaymentSection,
      rateChangeModalDisplayed: false,
      updatedRoom: {},
      nightlyRate: '',
      updatedItemId: 0,
      updateHotelId: 0,
      eventId:0
    }
  },
  computed: {
    ...mapState('checkout', {
      sectionComponent: state => state.section,
      topDiv: state => state.topDiv,
      hotelInCart: state => state.hotelInCart,
      number: state => state.number,
      // isGuest: state => state.isGuest,
    }),
    orderLoading(){
      return this.$store.state.order.loading
    },
    depositOrder(){
      return this.order && this.deposit_product
    },
    deposit_product(){
      return this.order.items.find(item => item.sf_type == 'Deposit')
    },
    disabledCart(){
      return this.order.disabled_cart
    },
    discountsAvailable(){
      return this.cart.active_discounts && !this.depositOrder
    },
    hasPaymentTerms() {
      return !(this.paymentTermOptions.slice(-1)[0] == 1)
    },
    loadingTerms(){
      return this.$store.state.order.loadingTerms
    },
    pendingTerms(){
      return this.$store.state.order.order.payment_terms.filter( term => !term.status || term.status == 'pending')
    },
    displayLeftColumn(){
      return this.discountsAvailable || this.order.customer_total_credit > 0 ||
        (this.paymentTermOptions && this.paymentTermOptions.length > 1) ||
        this.order.protect_group_cost > 0
    },
    paymentTermsForSelect() {
      return this.paymentTermOptions.map(option => {
        return { text: this.$t(`payment_term_${option}`), value: option }
      })
    },
    paymentTermOptions(){
      return this.order.event_payment_term_options
    },
    order() {
      return this.$store.state.order.order
    },
    cart() {
      return this.$store.state.order.cart
    },
  },
  watch: {
    hotelInCart() {
      if (!this.hotelInCart) { this.sections.splice(3, 1) }
    }
  },

  created(){
    this.removeFooterMargin()
    CartApi.hotelPriceChange().then(resp => {
      if(resp.hotel_rate_change){
        this.rateChangeModalDisplayed = true
        this.eventId = resp.event_id
        this.nightlyRate = resp.nightly_rate
        this.updatedItemId = resp.item_id
        this.updateHotelId = resp.hotel_id
        this.getBackendOrder()
      }
    })
  },
  async mounted() {
    // Delay Div load so it doesn't scroll to view on page load
    // let el = document.querySelector('#accountStatus').dataset.guest
    var current = this
    setTimeout(function() { current.setTopDiv(document.querySelector('.checkout-container')) }, 3000)
  },
  methods: {
    ...mapMutations('checkout', ['setTopDiv', 'turnOffCredit']),
    ...mapMutations('order', ['updateOrder', 'updateCart', 'updateLoading']),
    ...mapGetters('order', ['getCurrentOrder', 'emptyOrder']),
    ...mapActions('order', ['getBackendOrder']),
    failHotel() {
      this.getBackendOrder()
    },
    rejectRateChange() {
      CartApi.deleteH4hHotelItem(this.updatedItemId, this.updateHotelId, 'DELETE', null).then( () => {
        window.location.href = `/cart/events/${this.eventId}/hotels`
      })
    },
    removeFooterMargin() {
      // this is stupid but I don't see another way to do it since it's only this page
      document.querySelector('footer').style = 'margin-top: 0'
    },
    acceptRateChange(){
      const body = JSON.stringify({price_change_confirmation_needed: false})
      CartApi.confirmHotelRateChange(this.updatedItemId, 'PUT', body).then( () => {
        this.rateChangeModalDisplayed = false
      })
    },

    updateReservationFromAddress(){
      this.$refs.HotelDetails.updateReservationFromAddress()
    }
  }
}
</script>
