<template>
  <section
    v-if="numberOfAddOns > 0"
    class="open expandable_section add-on-group"
  >
    <h2
      class="expandable_header"
      @click="updateSection(sectionName)"
    >
      {{ $t('checkout.addOn.addOns') }}
    </h2>
    <span
      class="header-count"
      @click="updateSection(sectionName)"
    />

    <div class="expandable_content">
      <div class="add-on-items">
        <AddOn
          v-for="item in addOnItems"
          :key="item.id"
          :item="item"
          :currency="currency"
          :rate="rate"
          @refresh-add-ons="addOnChanged"
        />
      </div>

      <p
        :class="addOnClass"
        @click="addOnClass = 'hidden'"
      >
        {{ addOnAddedText }} <span class="delete" />
      </p>

      <button
        class="btn btn-primary continue-button"
        @click="updateSection(sectionNext)"
      >
        {{ $t('cookie.continue') }}
      </button>
    </div>
  </section>
</template>

<script>
import AddOn from './add_on'
import Api from '../cart/cart_api'
import { mapMutations, mapActions } from 'vuex'

export default {
  components: {
    AddOn
  },

  props: {
    number: {
      default: null,
      type: Number
    }
  },
  data() {
    return {
      addOnAddedText: '',
      addOnClass: 'hidden',
      addOnItems: [],
      currency: 'USD',
      rate: 1.0,
      sectionName: 'addOn',
      sectionNext: 'address'
    }
  },

  computed: {
    numberOfAddOns() {
      return this.addOnItems.length
    },

    selected() {
      return this.$store.state.checkout.section == this.sectionName
    }
  },

  created() {
    this.getAddOns().then(() => {
      if (this.numberOfAddOns > 0) {
        this.updateSection(this.sectionName)
      }
    })
  },

  methods: {
    ...mapMutations('checkout', ['updateSectionNumber']),
    ...mapActions('checkout', ['updateSection']),
    addOnChanged(item) {
      if (item.display_name) {
        this.addOnAddedText = this.$t('checkout.addOn.added', { name: item.display_name })
        this.addOnClass = 'add-on-message'
      } else if (item.errors) {
        this.addOnClass = 'add-on-message error'
        this.addOnAddedText = item.errors
      } else {
        this.addOnClass = 'hidden'
        this.addOnAddedText = ''
      }

      this.getAddOns()
    },

    getAddOns() {
      return Api.getAddOnItems().then(response => {
        if (response.a_la_cartes) {
          this.addOnItems = response.a_la_cartes
          this.currency = response.currency
          this.rate = response.rate
        }

        this.refreshAddOns()
      })
    },

    refreshAddOns() {
      // this.$emit('refresh-add-ons', this.numberOfAddOns)
      this.updateSectionNumber(this.numberOfAddOns)
    }
  }
}
</script>
