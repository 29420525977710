<template>
  <div
    id="summaryContainer"
    class="flex-column is-6 right fourty background-f4 right-column small-sticky top-unscrolled"
  >
    <OrderSummary />
  </div>
</template>
<script>
import OrderSummary from './order_summary.vue'

export default {
  components: {
    OrderSummary,
  },
  computed: {
    order(){
      return this.$store.state.order.order
    },
  }
}
</script>

