
<template>
  <div>
    <div
      id="infoHeader"
      @click="showTopSection()"
    >
      <div class="overflow-auto border-bottom-ccc">
        <h3 :class="[highlighted, 'pointer section-header']">
          {{ $t('checkout.information') }}
        </h3>
      </div>
    </div>
    <div
      id="infoContainer"
      class="infoContainer"
    >
      <div
        class="address-and-hotel open flex-column margin-bottom-0 padding-bottom-20 border-bottom-ccc section-body"
      >
        <h2
          class="expandable_header contact-information"
        >
          <!-- @click="proceed(sectionName)" -->
          {{ $t('checkout.address.contactInfo') }}
        </h2>
        <VForm
          id="emailPhone"
          as="section"
          class="checkout-address"
          :class="[order && order.disabled_cart ? 'disabled-form' : 'section column is-6 pl-0']"
        >
          <div
            v-if="isGuest == 'true'"
            id="checkoutEmail"
            class="required-field field"
          >
            <Field
              id="checkout_email_address"
              v-model="emailAddress"
              rules="required"
              name="email_address"
              type="email"
              placeholder="*Email Address"
              data-vv-validate-on="input"
              class="input"
              :disabled="paymentAttempted"
            />
            <ErrorMessage
              as="span"
              name="email_address"
              class="error"
            />
            <label
              v-if="querying"
              class="small-label loading"
            >
              <span class="loading-icon" />
              <em>...validating email</em>
            </label>
            <span
              class="error"
            />
            <p
              v-if="blockedEmail"
              id="blockedEmail"
              class="error"
            >
              {{ $t('checkout.address.blockedEmail', { phoneNumber: currentSitePhoneNumber }) }}
            </p>
            <CheckoutLogin
              v-if="(existingEmailAccount && !blockedEmail)"
              :email="emailAddress"
            />
          </div>


          <div
            v-else
            id="checkoutEmail"
            class="required-field field"
          >
            <Field
              id="email_address"
              v-model="emailAddress"
              rules="required"
              name="email_address"
              type="email"
              placeholder="*Email Address"
              data-vv-validate-on="input"
              :class="['input', disableEmail ? 'disabled' : '']"
              :disabled="disableEmail"
            />
            <ErrorMessage
              as="span"
              name="email_address"
              class="error"
            />
            <span
              class="error"
            />
            <p
              v-if="blockedEmail"
              id="blockedEmail"
              class="error"
            >
              {{ $t('checkout.address.blockedEmail', { phoneNumber: currentSitePhoneNumber }) }}
            </p>
            <CheckoutLogin
              v-if="(guestCheckout && existingEmailAccount && !blockedEmail)"
              :email="emailAddress"
            />
          </div>
          <div class="required-field field">
            <Field
              id="checkout_phone_number"
              v-model="billing.phone_number"
              name="phone_number"
              type="text"
              :rules="validatePhone"
              :placeholder="$t('checkout.address.phoneNumber')"
              :disabled="blockedEmail"
            />
            <ErrorMessage
              as="span"
              name="phone_number"
              class="error"
            />
          </div>

          <div
            v-if="WNBA"
            class="required-field field enabled"
          >
            <Field
              id="wnba_fan_group"
              v-model="wnba_fan_group"
              as="select"
              name="wnba_fan_group"
              rules="required"
            >
              <option
                value=""
                disabled
              >
                *Are you a member of any of the following fan groups?
              </option>
              <hr>

              <option
                value="1"
              >
                NCAA Alumni
              </option>
              <option
                value="2"
              >
                WNBA Season Ticket Holder
              </option>
              <option
                value="0"
              >
                Neither
              </option>
              <hr>
            </Field>
            <ErrorMessage
              as="span"
              name="wnba_fan_group"
              class="error"
            />
          </div>
        </VForm>
        <VForm
          id="checkoutAddress"
          as="section"
          class="open info-body"
          :class="[order && order.disabled_cart ? 'disabled-form' : 'section column is-6 pl-0']"
        >
          <h2
            v-if="order && order.disabled_cart"
            class="expandable_header"
          >
            {{ $t('checkout.address.billingAddress') }}
          </h2>
          <h2
            v-else
            class="expandable_header"
          >
            <!-- @click="proceed(sectionName)" -->
            {{ $t('checkout.address.billingAndShipping') }}
          </h2>

          <!-- <span
      class="header-count"
      @click="proceed()"
    /> -->

          <div class="expandable_content">
            <div
              class="checkout-address"
            >
              <p
                v-if="!order.disabled_cart"
                class="address-disclaimer"
              >
                {{ $t('checkout.address.disclaimer') }}
              </p>
              <div class="required-field field">
                <Field
                  id="checkout_first_name"
                  v-model="billing.first_name"
                  name="first_name"
                  type="text"
                  :placeholder="$t('checkout.address.firstName')"
                  rules="required"
                  :disabled="blockedEmail"
                  @keyup="resetReservation"
                />
                <ErrorMessage
                  as="span"
                  name="first_name"
                  class="error"
                />
              </div>

              <div class="required-field field">
                <Field
                  id="checkout_last_name"
                  v-model="billing.last_name"
                  name="last_name"
                  type="text"
                  :placeholder="$t('checkout.address.lastName')"
                  rules="required"
                  :disabled="blockedEmail"
                  @keyup="updateReservationFromAddressAndReset"
                />
                <ErrorMessage
                  as="span"
                  name="last_name"
                  class="error"
                />
              </div>

              <div class="corporate-checkbox field">
                <input
                  id="checkout-address-corporate"
                  v-model="billing.corporate"
                  type="checkbox"
                  :disabled="blockedEmail"
                >
                <span class="custom-checkbox" />
                <label
                  class="checkbox pl-1"
                  for="checkout-address-corporate"
                >{{ $t('checkout.address.corporate') }}</label>
              </div>

              <div
                v-if="billing.corporate"
                class="corporate-fields columns mt-2 mx-0 is-justify-content-space-between"
              >
                <input
                  v-model="billing.company_name"
                  type="text"
                  autocomplete="off"
                  class="input field column is-5"
                  :placeholder="$t('checkout.address.companyName')"
                  :disabled="blockedEmail"
                >

                <input
                  v-model="billing.purchase_order_number"
                  type="text"
                  autocomplete="off"
                  class="input field column is-5"
                  :placeholder="$t('checkout.address.purchaseOrderNumber')"
                >
              </div>

              <div class="required-field field aws-address">
                <Field
                  id="checkout_address"
                  v-model="billing.address"
                  class="google_address_autocomplete input"
                  name="address"
                  type="text"
                  :placeholder="$t('checkout.address.address1')"
                  rules="required"
                  :disabled="blockedEmail"
                />
                <ErrorMessage
                  as="span"
                  name="address"
                  class="error"
                />
                <span
                  v-if="awsSelectionError"
                  class="error"
                >{{ awsSelectionError }}</span>
                <div
                  v-if="locationSuggestions.length > 0"
                  class="location-suggestions"
                >
                  <ul class="location-suggestions-ul">
                    <li
                      v-for="(place, index) in locationSuggestions"
                      :key="index"
                      class="location-suggestion"
                      @click="selectSuggestedLocation(place)"
                    >
                      {{ place.text }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="non-required-field field">
                <input
                  v-model="billing.address2"
                  class="input"
                  type="text"
                  :placeholder="$t('checkout.address.address2')"
                  :disabled="blockedEmail"
                >
              </div>
              <div class="smaller-required-fields field">
                <div class="smaller-required-fields-row">
                  <div class="small required-field">
                    <Field
                      id="checkout_city"
                      v-model="billing.city"
                      class="input"
                      name="city"
                      type="text"
                      :placeholder="$t('checkout.address.city')"
                      rules="required"
                      :disabled="blockedEmail"
                    />
                    <ErrorMessage
                      as="span"
                      name="city"
                      class="error"
                    />
                  </div>

                  <div class="small required-field">
                    <Field
                      id="checkout_zip_code"
                      v-model="billing.zip_code"
                      class="input"
                      name="zip_code"
                      type="text"
                      :placeholder="$t('checkout.address.zipCode')"
                      rules="required"
                      :disabled="blockedEmail"
                    />
                    <ErrorMessage
                      as="span"
                      name="zip_code"
                      class="error"
                    />
                  </div>
                </div>
                <div
                  v-if="webOrder"
                  class="smaller-required-fields-row"
                >
                  <div class="required-field field control">
                    <Field
                      id="checkout_country"
                      v-model="billing.country"
                      as="select"
                      name="country"
                      class="select is-multiple"
                      rules="required"
                      :disabled="blockedEmail"
                      @change="updateCountryAndState"
                    >
                      <option
                        value=""
                        disabled
                      >
                        {{ $t('checkout.address.country') }}
                      </option>

                      <option
                        v-for="code in preferredCountriesList"
                        :key="code.code"
                        :value="code.code"
                      >
                        {{ code.translations[currentLocale] || code.name }}
                      </option>
                      <hr>

                      <option
                        v-for="selectedCountry in countriesList"
                        :key="selectedCountry.code"
                        :value="selectedCountry.code"
                      >
                        {{ selectedCountry.translations[currentLocale] || selectedCountry.name }}
                      </option>
                    </Field>
                    <ErrorMessage
                      as="span"
                      name="country"
                      class="error"
                    />
                  </div>

                  <div class="small required-field field control">
                    <Field
                      v-if="stateLists.length > 0"
                      id="checkout_state"
                      v-model="billing.state"
                      as="select"
                      name="state"
                      class="select is-multiple"
                      rules="required"
                    >
                      <option
                        value="missing state value"
                        disabled
                      >
                        {{ $t('checkout.address.state') }}
                      </option>
                      <option
                        v-for="selectedState in stateLists"
                        :key="selectedState.code"
                        :value="selectedState.code"
                      >
                        {{ selectedState.name != null ? selectedState.name : selectedState.code }}
                      </option>
                    </Field>
                    <ErrorMessage
                      as="span"
                      name="state"
                      class="error"
                    />
                  </div>
                </div>

                <div
                  v-else
                  class="smaller-required-fields-row"
                >
                  <div class="required-field field">
                    <Field
                      id="checkout_country"
                      v-model="billing.country"
                      name="country"
                      type="text"
                      rules="required"
                      :disabled="blockedEmail"
                    />
                    <ErrorMessage
                      as="span"
                      name="country"
                      class="error"
                    />
                  </div>

                  <div class="small required-field field">
                    <Field
                      id="checkout_state"
                      v-model="billing.state"
                      name="state"
                      type="text"
                      rules="required"
                    />
                    <ErrorMessage
                      as="span"
                      name="state"
                      class="error"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VForm>


        <section
          is="vue:HotelDetails"
          ref="HotelDetails"
        />
        <div
          v-if="!valid && displayErrors"
        >
          <span class="error help is-danger">{{ missingBillingItem }} is required</span>
        </div>
        <div
          class="width-100 flex align-center justify-content-end height-50 margin-top-15"
        >
          <button
            id="saveAddress"
            class="btn btn-secondary"
            @click="saveAddress"
          >
            Save & Continue
          <!-- need to translate -->
          </button>


          <div
            id="intent-error"
            class="hidden"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global locale */
import CountryData from '../../country_data'
import CheckoutLogin from '../checkout_login'
// import FetchCall from '../../local_fetch_call'
import HotelDetails from '../hotel_details'
import * as yup from 'yup'
import gsap from 'gsap'
import CartApi  from '../../cart/cart_api'
import { mapMutations, mapState, mapActions } from 'vuex'
import { Field, Form as VForm, ErrorMessage } from 'vee-validate'
export default {
  name: 'CheckoutAddress',
  components: {
    CheckoutLogin,
    HotelDetails,
    Field,
    VForm,
    ErrorMessage
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['number', 'updateres'],
  data() {
    return {
      validated: false,
      querying: false,
      emailAddress: '',
      existingEmailAccount: false,
      countriesList: [],
      sectionName: 'address',
      displayErrors: false,
      showRequired: false,
      locationSuggestions: [],
      stateLists: [],
      preferredCountry: 'US',
      defaultLocale: '',
      preferredCountries: [],
      preferredCountriesList: [],
      blockedEmail: false,
      billingSet: false,
      awsSelectionError: null,
      wnba_fan_group: null,
      billing: {
        address: '',
        address2: '',
        city: '',
        country: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        state: '',
        zip_code: '',
        previousAddress: ''
      }
    }
  },

  computed: {

    disableEmail() {
      return this.paymentAttempted || this.order.email
    },
    missingBillingItem(){
      let missingItems = []
      this.billing.first_name ? null : missingItems.push('First name')
      this.billing.last_name ? null : missingItems.push('Last name')
      this.billing.phone_number ? null : missingItems.push('Phone')
      this.billing.address ? null : missingItems.push('Address')
      this.billing.city ? null : missingItems.push('City')
      this.billing.state && this.billing.state != 'missing state value' ? null : missingItems.push('State')
      this.billing.country ? null : missingItems.push('Country')
      this.emailAddress ? null : missingItems.push('Email Address')
      this.billing.zip_code ? null : missingItems.push('Zip Code')
      this.missingWnbaFanGroup ? missingItems.push('WNBA Fan Group') : null

      return missingItems.join(', ')
    },
    valid() {
      return this.billing.first_name && this.billing.last_name && this.billing.phone_number &&
      this.billing.address && this.billing.city && this.billing.country &&
      !this.invalidCountry && this.validPostalCode && this.billing.state != 'missing state value' &&
      this.emailAddress && this.wnbaFanGroup
    },
    wnbaFanGroup() {
      if(this.WNBA){
        return this.wnba_fan_group
      } else {
        return true
      }
    },
    missingWnbaFanGroup(){
      if(this.WNBA){
        return this.wnba_fan_group == null || this.wnba_fan_group == ''
      } else {
        return false
      }
    },
    invalidCountry() {
      return CountryData.blockedCountries().concat(
        ['', null, undefined]).includes(this.billing.country)
    },
    validPostalCode() {
      if (['CA', 'US'].includes(this.billing.country)) {
        return this.billing.zip_code
      } else {
        // We only require code of US and Canada so empty zip is valid in all other cases
        return true
      }
    },

    missingState(){
      return this.addressErrors['state']
    },

    highlighted(){
      return this.showing ? 'brand-primary' : ''
    },
    showing(){
      return this.$store.state.elements.topSectionShowing
    },
    missingZip(){
      return this.addressErrors['zip_code']
    },
    webOrder(){
      return this.order.order_type == 'WebOrder'
    },
    missingCountry(){
      return this.addressErrors['country']
    },
    order() {
      return this.$store.state.order.order
    },
    disabledCart() {
      return this.$store.state.order.order.disabled_cart
    },
    ...mapState('checkout', {
      billing: state => state.billing,
      hotelInCart: state => state.hotelInCart,
      isGuest: state => state.isGuest,
      paymentAttempted: state => state.paymentAttempted
    }),
    guestAccount: {
      get() {
        return this.isGuest
      },
      set() {
        let el = document.querySelector('#accountStatus').dataset.guest
        this.updateIsGuest(el)
      }
    },
    blockedCountries() {
      return CountryData.blockedCountries().concat(['', null, undefined]).toString()
    },
    currentLocale() {
      return locale.toLowerCase()
    },
    WNBA(){
      return window.name == 'wnba'
    },
    currentSitePhoneNumber() {
      var location = document.getElementById('country-code').dataset.code
      var phoneNumber = window.phone_numbers[location] || window.phone_numbers['default']
      return phoneNumber
    },
  },
  watch: {
    order(){
      this.setEmailAddress()
      this.setBillingInfo()
      this.updateCountryAndState()
    },
    // missingState(){
    //   // this.errors.add({
    //   //   field: 'state',
    //   //   msg: 'This field is required',
    //   //   rule: 'required',
    //   // })
    // },
    // missingZip(){
    //   this.errors.add({
    //     field: 'zip_code',
    //     msg: 'This field is required and must be less than 20 characters',
    //     rule: 'required'
    //   })
    // },
    // missingCountry(){
    //   // this.errors.add({
    //   //   field: 'country',
    //   //   msg: 'This field is required',
    //   //   rule: 'required',
    //   // })
    // },
    country() {
      this.updateCountryAndState()
    },
    emailAddress: function(val) {
      this.updateEmailAddress(val)
      this.isExistingEmail(val)
    },
    disabledCart() {
      if(this.order.disabled_cart){
        this.setValues()
      }
    }
  },
  mounted() {
    let el = document.querySelector('#accountStatus').dataset.guest
    this.updateIsGuest(el)
    this.enableAutocomplete()
    this.isValidated()
  },
  async created() {
    this.countriesList = CountryData.countryNamesAndCodes()
    this.setValues()
    // this.saveAddress()
  },
  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('checkout', [
      'updateIsGuest',
      'updateFirstName',
      'updateLastName',
      // 'updatePaymentIntentId',
      // 'updatePaymentIntentClientSecret'
      //   // 'updateAddress1',
      //   'updateAddress2',
      'updateEmailAddress',
    //   'updateCity',
    //   'updateCompanyName',
    //   'updateCorporate',
    //   'updateCountry',
    //   'updatefirst_name',
    //   'updateLastName',
    //   'updatePhoneNumber',
    //   'updatePurchaseOrderNumber',
    //   'updateState',
    //   'updateZipCode'
    ]),
    ...mapMutations('order', ['updateLoading']),
    ...mapMutations('elements', [
      'displayTopSection',
      'displayMiddleSection',
      'displayBottomSection'
    ]),

    updateReservationFromAddress(){
      const firstName = this.billing.first_name
      const lastName = this.billing.last_name
      this.updateFirstName(firstName)
      this.updateLastName(lastName)
      this.$refs.HotelDetails.updateReservationFromAddress()
    },

    updateReservationFromAddressAndReset(){
      this.updateReservationFromAddress()
      this.resetReservation()
    },

    setEmailAddress(){
      if(this.emailAddress || this.isGuest == 'true'){return}

      this.emailAddress = this.order.email
    },

    setBillingInfo(){
      if(this.billingSet){return}

      if(this.order.first_name) {this.billing.first_name = this.order.first_name }
      if(this.order.last_name) {this.billing.last_name = this.order.last_name }
      if(this.order.phone) {this.billing.phone_number = this.order.phone }
      if(this.order.street_1) {this.billing.address = this.order.street_1 }
      if(this.order.street_2) {this.billing.address2 = this.order.street_2 }
      if(this.order.city) {this.billing.city = this.order.city }
      if(this.order.state) {this.billing.state = this.order.state }
      if(this.order.country) {this.billing.country = this.order.country }
      if(this.order.zip_code) {this.billing.zip_code = this.order.zip_code }
      this.billingSet = true
    },

    validatePhone(phoneValue){
      if(!phoneValue) {
        return 'This field is required'
      }

      if(phoneValue.length < 8){
        return 'Please enter a valid phone number'
      }
      return true
    },

    enableAutocomplete() {
      this.enableClickAway()

      let typingTimer = null
      const delay = 250

      Array.from(document.querySelectorAll('.google_address_autocomplete')).forEach(
        element => {
          element.addEventListener('input', (e) => {
            clearTimeout(typingTimer)
            if(this.billing.previousAddress.length > 0){
              typingTimer = setTimeout(() => {
                this.getLocationSuggestions(e.target.value) // Use e.target.value to get the input value
              }, delay)
            }
            this.billing.previousAddress = this.billing.address
          })
        }
      )

    },

    enableClickAway(){
      document.addEventListener('click', (event) => {
        if(this.locationSuggestions.length <= 0){ return }

        const isClickInside = event.target.closest('.location-suggestions')

        // If the click is outside the locationSuggestionsElement, perform your logic
        if (!isClickInside) {
          this.locationSuggestions = []
        }
      })
    },

    selectSuggestedLocation(place) {
      this.awsSelectionError = null
      CartApi.getLocationById(place.place_id).then((response) => {
        if (response && response.country) {
          this.locationSuggestions = []
          this.billing.address = response.street
          this.billing.city = response.city
          this.billing.zip_code = response.zip_code
          this.billing.country = response.country
          this.updateCountryAndState()
          this.billing.state = response.state
        } else {
          this.locationSuggestions = []
          this.awsSelectionError = 'There was an error selecting this address. Please fill out manually'
          // displayError
        }
      })

    },

    getLocationSuggestions(value) {
      CartApi.getLocationSuggestions(value).then((response) => {
        if (response && response.length > 0) {
          this.locationSuggestions = response
        } else {
          this.locationSuggestions = []
        }
      })
    },

    setAddressFields(caseValue, fieldValue = '') {
      switch (caseValue) {
      case 'street_number':
        this.billing.address = fieldValue
        break
      case 'route':
        this.billing.address += ` ${fieldValue}`
        break
      case 'locality':
        this.billing.city = fieldValue
        break
      case 'postal_code':
        this.billing.zip_code = fieldValue
        break
      case 'country':
        this.billing.country = fieldValue
        break
      case 'administrative_area_level_1':
        this.billing.state = fieldValue
        break
      default:
        break
      }
    },
    resetReservation() {
      // set timer for 1 second
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.UpdateReservation()
      }, 1000)
    },
    UpdateReservation(){
      // after pause of typing for 1 second
      // if the reservation input is not filled out, and we have the first and last name from form
      // select emit action to update reservation on hotel details
      const resName = document.getElementById('reservationName')
      if(this.first_name && this.last_name && resName && resName.value == ''){
        if(resName.value != this.first_name + ' ' + this.last_name) {
          if(Array.from(document.getElementsByClassName('reservation-name'))){
            Array.from(document.getElementsByClassName('reservation-name')).forEach( this.$emit('updateres') )
          }
        }
      }
    },
    setValues() {
      this.billing.first_name = this.order.first_name
      this.billing.last_name = this.order.last_name
      // this.billing.corporate = this.order.corporate_group
      this.billing.phone_number = this.order.phone
      this.billing.address = this.order.street_1
      this.billing.address2 = this.order.street_2
      this.billing.city = this.order.city
      this.billing.state = this.order.state
      this.billing.country = this.order.country
      this.billing.zip_code = this.order.zip_code
      if(this.order.disabled_cart_guest_email){
        this.emailAddress = this.order.disabled_cart_guest_email
      }
      if (this.order.items  && this.order.items.length > 0) {
        if(this.order.items[0].preferred_country) this.preferredCountry = this.order.items[0].preferred_country
        this.defaultLocale = this.order.items[0].site_default_locale
        if (this.defaultLocale == 'en-GB'){
          this.preferredCountries = ['GB', 'US']
        } else {
          this.preferredCountries = ['US']
        }
        if (this.preferredCountry != 'US') this.preferredCountries.push(this.preferredCountry)
      }
      let that = this
      let tempCountriesList = { ...that.countriesList }
      Object.keys(this.countriesList).forEach(function(key){
        if(that.preferredCountries.includes(that.countriesList[key].code)){
          delete that.countriesList[key]
        } else {
          delete tempCountriesList[key]
        }
      })
      this.preferredCountries.forEach(function(key){
        if(tempCountriesList[key]){
          that.preferredCountriesList.push(tempCountriesList[key])
        }
      })
    },
    checkRequiredFields() {
      this.$validator.validate().then(valid => {
        if (valid) {
          // let sectionNext = this.hotelInCart ? 'hotel' : 'review'
          this.proceed()
        } else {
          // Don't continue with invalid fields
          for (let field of this.$validator.fields) { field.update({ events: 'input|blur' }) }
        }
      })
    },
    showTopSection(){
      this.displayTopSection()
    },

    updateCountryAndState() {
      CountryData.countryStates(this.billing.country).then(states => {
        this.stateLists = (states == undefined ? [] : states)
        let stateWithNoCode = this.stateLists.find(state=>{
          return this.billing.state == state.english_translation
        })
        let stateFound = this.stateLists.find(state=>{
          return this.billing.state == state['code']
        })
        if(this.webOrder){

          if(stateWithNoCode){
            this.billing.state = stateWithNoCode['code']
          } else if(!stateFound){
            this.billing.state = 'missing state value'
          }
          if (this.stateLists.length > 0) {
            this.billing.state = this.billing.state ? this.billing.state : 'missing state value'
          } else {
            this.billing.state = null
          }
        }
        else {
          this.billing.state = this.order.state
        }
        this.$emit('payment-fields-update', { country: this.billing.country, state: this.billing.state })
      })
    },

    async saveAddress(){
      if(this.valid) {
        if(this.isGuest == 'true'){
          let response = await CartApi.updateGuestUser(this.emailAddress)
          if (response.ok) {
            this.postAddress()
          } else {
            this.displayErrors = true
          }
        } else {
          this.postAddress()
        }
      } else {
        this.displayErrors = true
      }
    },

    async postAddress(){
      let params = {...this.billing, ...{email: this.emailAddress}}
      if(this.WNBA) {
        params = {...params, ...{group: this.wnba_fan_group}}
      }
      let body = JSON.stringify({intent: params})
      this.updateLoading(true)
      await CartApi.saveAddress(body).then( intent => {
        this.updateLoading(false)
        if(intent.address_errors){
          this.displayErrors = true
        } else {
          this.getBackendOrder()
          this.displayNextSection()
        }
      })
    },

    displayNextSection(){
      if(document.getElementById('protectGroupForm')) {
        this.displayMiddleSection()
      } else {
        this.displayBottomSection()
      }
    },

    showMessage(messageText) {
      const messageContainer = document.querySelector('#intent-error')

      messageContainer.classList.remove('hidden')
      messageContainer.textContent = messageText

      setTimeout(function () {
        messageContainer.classList.add('hidden')
        messageText.textContent = ''
      }, 4000)
    },
    expandDiv() {
      gsap.to('.infoContainer', {duration: 1, height: 'auto', ease: 'power2.inOut'})
    },
    shrinkDiv(){
      gsap.to('.infoContainer', {duration: 1, height: '0', ease: 'power2.inOut'})
    },
    apiUserEmail(method, body) {
      this.validating = true
      let tok = document.getElementsByTagName('meta')['csrf-token']
      if (tok) { // csrf-token is null in testing
        tok = tok.getAttribute('content')
      }
      fetch('/customers/customer_email_validations', {
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': tok
        },
        method: method,
        body: body
      }).then((response) => {
        if(response.status == 200) {
          this.existingEmailAccount = true
          this.validated = false
        }
        else if (response.status == 403) {
          this.blockedEmail = true
          this.addsDisabledClassnameToInputs()
        } else if (!response.ok) {
          this.existingEmailAccount = false
          this.validated = true
        }
      })
    },
    addsDisabledClassnameToInputs() {
      setTimeout(() => {
        document.querySelectorAll('input').forEach((e) => { if(e.disabled) {e.classList.add('disabled')} })
      }, '1')
    },
    queryEmailAddress(){
      let schema = yup.string().email()
      let validEmail = schema.isValidSync(this.emailAddress)

      if (validEmail) {
        this.apiUserEmail('POST', JSON.stringify({
          customer: {
            email: this.emailAddress
          }
        }))
      } else {
        this.existingEmailAccount = false
      }
    },
    isExistingEmail() {
      if(!this.querying) {
        this.querying = true
        setTimeout(() => {
          this.queryEmailAddress()
          this.querying = false
        }, 1200)
      }
    },
    isValidated() {
      if(this.isGuest == 'false') {
        this.validated = true
      }
    },
  }
}
</script>
