<template>
  <div
    id="checkoutVue"
    class="checkout-vue-form"
  >
    <section
      is="vue:Cart"
    />
    <section
      is="vue:AddOns"
    />
    <div class="address-and-hotel">
      <section
        is="vue:CheckoutAddress"
        @updateres="updateReservationFromAddress"
      />
      <section
        is="vue:HotelDetails"
        ref="HotelDetails"
      />
    </div>
    <div class="open middle-checkout-column columns is-justify-content-space-between">
      <div
        class="cart left-middle-checkout-column section column is-6"
        :class="[ displayLeftColumn ? 'display' : 'no-display']"
      >
        <div v-if="order.credit_amount <= 0">
          <PointsSelection />
        </div>
        <div
          v-if="(discountsAvailable || order.customer_total_credit > 0) && !disabledCart"
          class="credits-and-discounts tile is-vertical"
        >
          <h2
            v-if="discountsAvailable || order.customer_total_credit > 0"
            class="expandable_header credits-discounts"
          >
            {{ $t('checkout.payment.creditsAndDiscounts') }}
          </h2>
          <Credits />
          <section
            is="vue:DiscountForm"
            v-if="discountsAvailable"
            :activediscounts="cart.active_discounts"
          />
        </div>
        <section
          is="vue:Protected"
        />
        <section
          is="vue:TermForm"
          v-if="paymentTermOptions && paymentTermOptions.length > 1 && !disabledCart"
          :options="paymentTermOptions"
        />
        <div
          v-if="order.payment_terms && order.payment_terms.length > 1 && paymentTermOptions.length > 1 && !disabledCart"
        >
          <PaymentTermDisplay
            v-for="term in order.payment_terms"
            :key="term.id"
            :term="term"
          />
        </div>
        <div :class="[loadingTerms ? 'loading' : '']">
          <div class="loading-icon" />
        </div>
      </div>
      <div class="right-middle-checkout-column column is-6">
        <section
          is="vue:CheckoutReview"
          ref="CheckoutReview"
          @updateorder="updateOrder($event)"
        />
        <div
          v-if="disabledCart"
          class="quote-payment-terms"
        >
          <p class="header">
            {{ $t('checkout.payment.yourPayments') }}
          </p>
          <section
            is="vue:DisabledCartTermDisplay"
            v-for="(term, i) in pendingTerms"
            :key="term.id"
            :currency="order.charge_currency"
            :index="i+1"
            :term="term"
          />
        </div>
      </div>
    </div>
    <section
      is="vue:CheckoutPayment"
      @failhotel="failHotel()"
    />
    <div id="app">
      <HotelRateChangeModal
        v-show="rateChangeModalDisplayed"
        :room="updatedRoom"
        :total="nightlyRate"
        @reject="rejectRateChange"
        @accept="acceptRateChange"
      />
    </div>
  </div>
</template>

<script>
import AddOns from './add_ons'
import CheckoutAddress from './address'
import HotelDetails from './hotel_details'
import Cart from './sidebar_cart'
import CartApi from '../cart/cart_api'
import CheckoutPayment from './payment'
import Credits from '../cart/credit_selection'
import PointsSelection from './points_selection'
import DiscountForm from '../cart/discount_form'
import TermForm from '../cart/payment_terms/term_form'
import PaymentTermDisplay from '../cart/payment_terms/term_display'
import DisabledCartTermDisplay from '../cart/payment_terms/disabled_cart_term_display'
import Protected from './protected'
import CheckoutReview from './review'
import HotelRateChangeModal from './confirm_hotel_rate_change'

import { mapMutations, mapGetters, mapState, mapActions} from 'vuex'
export default {
  components: {
    CheckoutAddress,
    AddOns,
    PointsSelection,
    Cart,
    Credits,
    DiscountForm,
    HotelDetails,
    CheckoutPayment,
    CheckoutReview,
    HotelRateChangeModal,
    TermForm,
    PaymentTermDisplay,
    DisabledCartTermDisplay,
    Protected,
  },
  data() {
    return {
      currentSection: 'none',
      SidebarCart:Cart,
      Credits: Credits,
      DiscountForm: DiscountForm,
      CheckoutAddress: CheckoutAddress,
      HotelDetails: HotelDetails,
      CheckoutReview: CheckoutReview,
      TermForm: TermForm,
      // PointsSelection: PointsSelection,
      PaymentTermDisplay: PaymentTermDisplay,
      CheckoutPayment: CheckoutPayment,
      CheckoutProtected: Protected,
      AddOns: AddOns,
      DisabledCartTermDisplay: DisabledCartTermDisplay,
      rateChangeModalDisplayed: false,
      updatedRoom: {},
      nightlyRate: '',
      updatedItemId: 0,
      updateHotelId: 0,
      eventId:0
    }
  },
  computed: {
    ...mapState('checkout', {
      sectionComponent: state => state.section,
      topDiv: state => state.topDiv,
      hotelInCart: state => state.hotelInCart,
      number: state => state.number,
      // isGuest: state => state.isGuest,
    }),
    depositOrder(){
      return this.order && this.deposit_product
    },
    deposit_product(){
      return this.order.items.find(item => item.sf_type == 'Deposit')
    },
    disabledCart(){
      return this.order.disabled_cart
    },
    discountsAvailable(){
      return this.cart.active_discounts && !this.depositOrder
    },
    hasPaymentTerms() {
      return !(this.paymentTermOptions.slice(-1)[0] == 1)
    },
    loadingTerms(){
      return this.$store.state.order.loadingTerms
    },
    pendingTerms(){
      return this.$store.state.order.order.payment_terms.filter( term => !term.status || term.status == 'pending')
    },
    displayLeftColumn(){
      return this.discountsAvailable || this.order.customer_total_credit > 0 ||
      (this.paymentTermOptions && this.paymentTermOptions.length > 1) ||
      this.order.protect_group_cost > 0
    },
    paymentTermsForSelect() {
      return this.paymentTermOptions.map(option => {
        return { text: this.$t(`payment_term_${option}`), value: option }
      })
    },
    paymentTermOptions(){
      return this.order.event_payment_term_options
    },
    order() {
      return this.$store.state.order.order
    },
    cart() {
      return this.$store.state.order.cart
    },
  },
  watch: {
    hotelInCart() {
      if (!this.hotelInCart) { this.sections.splice(3, 1) }
    }
  },

  created(){
    CartApi.hotelPriceChange().then(resp => {
      if(resp.hotel_rate_change){
        this.rateChangeModalDisplayed = true
        this.eventId = resp.event_id
        this.nightlyRate = resp.nightly_rate
        this.updatedItemId = resp.item_id
        this.updateHotelId = resp.hotel_id
        this.getBackendOrder()
      }
    })
  },
  async mounted() {
    // Delay Div load so it doesn't scroll to view on page load
    // let el = document.querySelector('#accountStatus').dataset.guest
    var current = this
    setTimeout(function() { current.setTopDiv(document.querySelector('.checkout-container')) }, 3000)
  },
  methods: {
    ...mapMutations('checkout', ['setTopDiv', 'turnOffCredit']),
    ...mapMutations('order', ['updateOrder', 'updateCart']),
    ...mapGetters('order', ['getCurrentOrder', 'emptyOrder']),
    ...mapActions('order', ['getBackendOrder']),
    failHotel() {
      this.getBackendOrder()
    },
    rejectRateChange() {
      CartApi.deleteH4hHotelItem(this.updatedItemId, this.updateHotelId, 'DELETE', null).then( () => {
        window.location.href = `/cart/events/${this.eventId}/hotels`
      })
    },
    acceptRateChange(){
      const body = JSON.stringify({price_change_confirmation_needed: false})
      CartApi.confirmHotelRateChange(this.updatedItemId, 'PUT', body).then( () => {
        this.rateChangeModalDisplayed = false
      })
    },
    updateReservationFromAddress(){
      this.$refs.HotelDetails.updateReservationFromAddress()
    }
  }
}
</script>
