<template>
  <div class="amenity-description">
    <h4>
      <b>{{ $t('h4h.amenities') }}</b>
    </h4>
    <p
      v-for="(amenity, index) in filteredAmenities"
      :key="index"
    >
      {{ amenity }}
    </p>
    <p
      class="show-btn"
      @click="showToggle(default_limit, amenities.length)"
    >
      {{ limit_by === 5 ? $t('h4h.showMore') : $t('h4h.showLess') }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    amenities: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      default_limit: 5,
      limit_by: 5,
      filteredAmenities: this.amenities.slice(0, 5)
    }
  },

  watch: {
    limit_by: function() {
      this.filteredAmenities = this.amenities.slice(0, this.limit_by)
    }
  },

  methods: {
    showToggle(limit, length) {
      this.limit_by =
        (this.limit_by === limit) ? length : limit
    }
  }
}
</script>
