<template>
  <select
    id="vue_hotel_id"
    v-model="currentHotel"
    data-ga-event-action="Hotel"
    data-ga-event-category="Package-Form"
    class="select optional form-control"
    @change="emitSelectedHotel()"
  >
    <option
      v-if="!hotelRequired"
      :value="null"
    >
      {{ $t('formPackage.noAccommodations') }}
    </option>

    <option
      v-for="hotel in hotels"
      :key="hotel.id"
      :value="hotel"
    >
      {{ hotel.display_name_with_fallback }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    hotelRequired: {
      default: false,
      required: true,
      type: Boolean
    },

    // eslint-disable-next-line vue/require-prop-types
    hotels: {
      default: [],
      required: true,
    },

    // eslint-disable-next-line vue/require-prop-types
    selectedHotel: {
      default: null,
      required: true
    }
  },

  data() {
    return {
      currentHotel: null,
    }
  },

  watch: {
    selectedHotel() {
      this.currentHotel = this.selectedHotel
    }
  },

  mounted() {
    this.currentHotel = this.selectedHotel
    this.hotels.forEach(hotel => {
      hotel.stays.sort((a,b) => {
        return a.price - b.price
      })
    })
  },

  methods: {
    emitSelectedHotel() {
      this.$emit('hotel-emit', this.currentHotel)
    }
  }
}
</script>
