<template>
  <section class="checkout-payment-select open margin-bottom-40">
    <h2 class="payment-term-header expandable-header">
      <!-- Your Payments -->

      {{ $t('paymentTerms.payment_terms') }}
    </h2>
    <!-- eslint-disable vue/require-v-for-key -->

    <div class="payment-select field">
      <div
        v-if="!order.disabled_cart"
      >
        <div
          v-for="p in paymentTermsForSelect"
          :class="p.value == paymentCount ? 'selected' : ''"
          class="flex align-start pointer border-ccc margin-bottom-5"
        >
          <input
            :id="'term' + p.value"
            :key="p.value"
            v-model="paymentCount"
            type="radio"
            :value="p.value"
            class="margin-5-10 margin-top-15 display-none"
            @change="updatePaymentCount()"
          >
          <label
            :for="'term' + p.value"
            class="flex-grow flex flex-row-no-bs align-center pointer margin-5-10 font-weight-300"
          >
            <div :class="p.value == paymentCount ? 'option-selected' : 'option-unselected'">
            <!-- <p>svg goes here</p> -->
            </div>
            <div class="flex-grow flex flex-column pointer margin-5-10 font-weight-300">

              {{ p.text }}
              <PaymentTermOption
                :index="p.index"
              />
            </div>
          </label>
        </div>
      </div>
      <div
        v-else
        class="flex align-start pointer border-ccc margin-bottom-5"
      >
        <input
          id="disabledterm"
          type="radio"
          class="margin-5-10 margin-top-15 display-none"
        >
        <label
          for="disabledterm"
          class="flex-grow flex flex-row align-center pointer margin-5-10 font-weight-300"
        >
          <div class="option-selected">
            <!-- <p>svg goes here</p> -->
          </div>
          <div class="flex-grow flex flex-column pointer margin-5-10 font-weight-300">

            <!-- <PaymentTermOption
            :index="1"
            /> -->
            <DisabledCartTermDisplay
              v-for="(term, i) in order.payment_terms.filter( term => !term.status || term.status == 'pending')"
              :key="term.id"
              :currency="order.charge_currency"
              :index="i+1"
              :term="term"
            />
          </div>
        </label>
      </div>
      <div
        v-if="ers"
        class="errors"
      >
        <span>{{ ers }}</span>
        <span
          class="delete"
          @click="ers = null"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Api from '../../cart/cart_api'
import PaymentTermOption from './payment_term_option.vue'
import DisabledCartTermDisplay from '../../cart/payment_terms/disabled_cart_term_display'
import { mapActions, mapMutations } from 'vuex'

export default {
  components: {
    PaymentTermOption,
    DisabledCartTermDisplay
  },
  props: {
    options: {
      default: () => [],
      type: Array
    }
  },

  data() {
    return {
      ers: null,
      paymentCount: 1,
    }
  },
  computed: {
    order(){
      return this.$store.state.order.order
    },
    disabledCart(){
      return this.order.disabled_cart
    },
    orderpaymentcount(){
      return this.$store.state.order.order.payment_count
    },
    paymentTermsForSelect() {
      return this.order.payment_term_options.map((option, index) => {
        return { text: this.$t(`payment_term_${option.length}`), index: index, value: option.length }
      })
    },
  },
  watch: {
    orderpaymentcount() {
      this.paymentCount = this.orderpaymentcount
    },
  },

  created() {
    this.paymentCount = this.orderpaymentcount
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('order', ['updateLoadingTerms']),
    ...mapMutations('order', ['updateLoading']),
    updatePaymentCount() {
      this.updateLoading(true)
      Api.changeOrder(JSON.stringify({ order: { payment_count: this.paymentCount } })).then((order) => {
        this.getBackendOrder().then( () => this.updateLoading(false) )
        if (order.id == undefined) {
          this.paymentCount = this.orderpaymentcount
          this.ers = this.$t('packageNotUpdated')
        }

      })

    },
  },
}
</script>
