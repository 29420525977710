<template>
  <tbody
    class="display-contents"
  >
    <HotelRow
      v-for="hotel in hotels"
      :key="hotel.id"
      :hotel="hotel"
      :latitude="eventLatitude"
      :longitude="eventLongitude"
      :transfers="transfers"
      :display-check-in-check-out="displayCheckInCheckOut"
    />
  </tbody>
</template>
<script>
import HotelRow from './hotel-row'
export default {
  components: {
    HotelRow
  },

  data (){
    return {
      hotels: [],
      eventLatitude: 0,
      eventLongitude: 0,
      transfers: true,
      displayCheckInCheckOut: true
    }
  },

  created(){
    const location = JSON.parse(document.getElementById('contractHotels').dataset.event_location)
    this.eventLatitude = location['latitude']
    this.eventLongitude = location['longitude']
    this.displayCheckInCheckOut = JSON.parse(document.getElementById('contractHotels').dataset.display_check_in_check_out)
    this.hotels = JSON.parse(document.getElementById('contractHotels').dataset.hotels)
    this.transfers = JSON.parse(document.getElementById('contractHotels').dataset.transfers)
  }
}
</script>
