<template>
  <div
    class="new_order_item all-packages single-package"
  >
    <div class="package-select-row">
      <label
        class="select optional control-label package-pricing-label"
      >
        {{ packages[0].display_name }}
      </label>
      <label
        class="select optional control-label price-pricing-label"
      />
      <label
        class="select optional control-label num-of-people-pricing-label"
      >
        {{ packageInventoryText }}
      </label>
    </div>
    <SinglePackageItem
      v-for="pack in packages"
      :key="pack.id"
      :pack="pack"
      :rate="rate"
      :currency="currency"
      :packages="packages"
      :selected-package="$store.state.packages.selectedPackage"
      @package-emit="updateSelectedPackage"
    />
  </div>
</template>

<script>
import { CurrencyApi } from '../../../default/currency_convert'
import SinglePackageItem from './single-package-item'
import { mapMutations } from 'vuex'

export default {
  components: {
    SinglePackageItem,
  },
  props: {
    packages: {
      default: new Array(),
      required: true,
      type: Array
    },

    currency: {
      default: 'USD',
      required: true,
      type: String
    },

    rate: {
      default: 1.0,
      required: true,
      type: Number
    }
  },

  data() {
    return {
      packageQty: 1,
    }
  },

  computed: {
    packageInventoryText() {
      return this.$t('formPackage.numberOfPeople')
    }
  },

  watch: {
    packages() {
      this.refreshPackages()
    }
  },

  created() {
    this.refreshPackages(this.packages)
    this.resetPackageQty()
  },

  methods: {
    ...mapMutations('packages', [
      'refreshPackages',
      'updateSelectedPackage'
    ]),

    emitPackageQty() {
      this.$emit('qty-emit', this.packageQty)
    },

    packageName(pack) {
      if (pack == null) { return }

      return pack.display_name
    },

    packagePrice(pack){

      if (pack.price > 0 && !pack.hide_pricing) {
        return CurrencyApi.formatAmount(pack.price * this.rate, this.currency)
      }
    },

    resetPackageQty() {
      this.$emit('package-emit', this.$store.state.packages.selectedPackage)
      if (this.$store.state.packages.selectedPackage.inventory_on_site < this.qtyPackage) {
        this.packageQty = 1
        this.emitPackageQty()
      }
    }
  }
}
</script>
