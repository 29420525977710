<template>
  <div id="paymentSection">
    <div
      id="paymentSectionHeader"
      class="overflow-auto pointer border-bottom-ccc"
      @click="displayBottomSection()"
    >
      <h3 :class="[highlighted, 'section-header']">
        {{ $t('cart.paymentCount') }}
      </h3>
    </div>

    <div
      id="paymentSectionContainer"
      class="paymentSectionContainer"
    >
      <div class="section-body">
        <section
          is="vue:TermForm"
          v-if="(paymentTermOptions && paymentTermOptions.length > 1) || disabledCart"
          :options="paymentTermOptions"
        />
        <h2 class="payment-term-header expandable-header font-size-18">
          {{ $t("checkout.payment.paymentMethod") }}
        </h2>
        <ElementsForm />
      </div>
    </div>
  </div>
</template>
<script>

import CartApi from '../../cart/cart_api'
import ElementsForm from './elements_form'
import TermForm from './term_form'

import { mapMutations, mapGetters, mapActions} from 'vuex'
export default {
  components: {
    ElementsForm,
    TermForm,
  },
  data() {
    return {
      TermForm: TermForm,
      ElementsForm: ElementsForm
    }
  },
  computed: {

    highlighted(){
      return this.showing ? 'brand-primary' : ''
    },
    showing(){
      return this.$store.state.elements.bottomSectionShowing
    },
    disabledCart(){
      return this.order.disabled_cart
    },
    hasPaymentTerms() {
      return !(this.paymentTermOptions.slice(-1)[0] == 1)
    },
    loadingTerms(){
      return this.$store.state.order.loadingTerms
    },
    pendingTerms(){
      return this.$store.state.order.order.payment_terms.filter( term => !term.status || term.status == 'pending')
    },

    paymentTermsForSelect() {
      return this.paymentTermOptions.map(option => {
        return { text: this.$t(`payment_term_${option}`), value: option }
      })
    },
    paymentTermOptions(){
      return this.order.event_payment_term_options
    },
    order() {
      return this.$store.state.order.order
    },
  },
  watch: {
    hotelInCart() {
      if (!this.hotelInCart) { this.sections.splice(3, 1) }
    }
  },

  methods: {
    ...mapMutations('checkout', ['setTopDiv', 'turnOffCredit']),
    ...mapMutations('order', ['updateOrder', 'updateCart']),
    ...mapGetters('order', ['getCurrentOrder', 'emptyOrder']),
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('elements', [
      'displayBottomSection',
    ]),
    failHotel() {
      this.getBackendOrder()
    },
    rejectRateChange() {
      CartApi.deleteH4hHotelItem(this.updatedItemId, this.updateHotelId, 'DELETE', null).then( () => {
        window.location.href = `/cart/events/${this.eventId}/hotels`
      })
    },
    acceptRateChange(){
      const body = JSON.stringify({price_change_confirmation_needed: false})
      CartApi.confirmHotelRateChange(this.updatedItemId, 'PUT', body).then( () => {
        this.rateChangeModalDisplayed = false
      })
    },

    updateReservationFromAddress(){
      this.$refs.HotelDetails.updateReservationFromAddress()
    }
  }
}
</script>
