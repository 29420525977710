<template>
  <div :class="['cart-footer', extraclass]">
    <p class="header">
      {{ $t('totalDueToday') }}
    </p>
    <p class="amount">
      <span class="currency">
        {{ currency }}
      </span>
      {{ amount }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    extraclass: {
      default: null,
      type: String
    }},
  computed: {
    amount() {
      return this.order.amount_due_today_decorated
    },
    order() {
      return this.$store.state.order.order
    },
    currency() {
      return this.order.charge_currency
    },
    header() {
      return this.$t('totalDueToday')
    }
  }
}
</script>
