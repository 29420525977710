/* global locale */

import { messages } from '../../default/translated_text'

let translations = {}

const addCount = () => {
  let headerCountDiv = document.querySelector('.add-on-group .header-count')
  if (headerCountDiv == null) { return }

  let allItems = document.querySelectorAll('.add-on-items .owl-item')
  if (allItems == null) { return }

  let activeItems = []
  for (let i = 0; i < allItems.length; i++) {
    if (allItems[i].classList.contains('active')) { activeItems.push(i + 1) }
  }

  updateHeaderText(headerCountDiv,
    activeItems.sort()[0],
    activeItems.sort()[activeItems.length - 1],
    allItems.length)
}

const addOwlCarousel = () => {
  $('.add-on-group .add-on-items').owlCarousel({
    dots: false,
    margin: 25,
    loop: false,
    nav: true,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>'
    ],
    navContainer: '.add-on-group .add-on-items',
    onDragged: addCount,
    onResized: addCount,
    onTranslated: addCount,

    responsive: {
      0: { items: 1 },
      768: { items: 2 },
      992: { items: 3 }
    }
  })
}

const updateHeaderText = (header, startCount, endCount, total) => {

  if (startCount == endCount) {
    header.innerHTML = translations.item.
      replace('{startCount}', startCount).replace('{total}', total)
  } else {
    header.innerHTML = translations.items.
      replace('{startCount}', startCount).replace('{endCount}', endCount).replace('{total}', total)
  }
}

const updateVueAddOnCart = () => {
  addOwlCarousel()
  addCount()
}

export default {
  loadWithAddOnAndCarousel(cart) {
    if (cart === undefined) { return }

    translations = (messages[locale] || messages['en']).checkout.addOn

    cart.$children[0].$on('add-on-changed', () => {
      setTimeout(() => {
        $('.carousel-display').removeClass('carousel-display')
        updateVueAddOnCart()
      }, 5)
    })
  }
}
