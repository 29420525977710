<template>
  <div
    v-if="order.customer_total_credit > 0"
    class="open field credit-amount-checkbox"
  >
    <div
      v-if="order.credit_applicable"
      class="credits-checkbox-and-label field"
    >
      <input
        v-model="creditUsed"
        type="checkbox"
        checked="on"
        class="credit-checkbox"
        @change="updateCreditApplied()"
      >
      <span><b>{{ $t('cart.applyCredit') }}: </b>{{ customerCredit }}</span>
    </div>
    <div
      v-else
    >
      <span>{{ $t('cart.creditNotApplicable') }}</span>
    </div>
  </div>
</template>

<script>
import CartApi from './cart_api'
import { mapMutations, mapActions } from 'vuex'
export default {

  computed: {
    order(){
      return this.$store.state.order.order
    },
    customerCredit(){
      return this.order.customer_total_credit_decorated
    },
    creditUsed: {
      get() {
        return this.order.credit_amount > 0
      },
      set() {
        this.toggleCreditApplication()
      }
    }
  },

  created(){
    this.applyInitialCredit()
  },

  methods: {
    ...mapMutations('checkout', [ 'toggleCreditApplication', 'updateCreditAmount']),
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('order', ['updateLoading']),

    applyInitialCredit(){
      if( this.order.customer_total_credit > 0 && this.order.credit_amount == 0 && this.order.credit_applicable ){
        this.updateOrderInStore(JSON.stringify({ order: { credit_amount: this.order.customer_total_credit } }))
      }
    },
    updateCreditApplied(){
      this.updateLoading(true)
      if(this.order.credit_amount == 0 && this.order.customer_total_credit > 0 && this.order.credit_applicable){
        this.updateCreditAmount(this.order.customer_total_credit)
      } else {
        this.updateCreditAmount(0)
      }
      this.updateOrderInStore(JSON.stringify({ order: { credit_amount: this.$store.state.checkout.creditApplied } }))
    },
    updateOrderInStore(body){
      CartApi.changeOrder(body).then( () => {
        this.getBackendOrder().then( () => this.updateLoading(false) )
      })
    }
  }
}
</script>
