<template>
  <section
    id="checkoutReviewOrder"
    class="column is-11"
    :class="['open', emptyCartClass, 'expandable_section', 'review-section']"
  >
    <h2
      class="expandable_header"
    >
      {{ $t('checkout.payment.reviewOrder') }}
    </h2>

    <Cart
      ref="Cart"
      :checkoutpage="true"
      @amount-update="updateStatePaymentFields"
      @updateorder="updateOrder($event)"
    />
  </section>
</template>

<script>
import Cart from '../cart/cart'
import { mapState } from 'vuex'

export default {
  name: 'CheckoutReview',
  components: {
    Cart
  },

  props: {
    number: {
      type: Number,
      default: 0
    },
    updateorder:{
      type: Function,
      default: null
    }
  },

  data() {
    return {
      cartHasItems: true,
      sectionName: 'review',
      sectionNext: 'payment',
    }
  },

  computed: {
    ...mapState('checkout', {
      section: state => state.section
    }),
    emptyCartClass() {
      return (this.cartHasItems) ? '' : 'empty'
    }
  },

  methods: {
    updateStatePaymentFields(newFields) {
      if (newFields.itemCount > 0) {
        this.cartHasItems = true
      } else {
        this.cartHasItems = false
      }
    },
    updateOrder(order){
      this.$emit('updateorder', order)
    }
  }
}
</script>
