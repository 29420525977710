<template>
  <div
    v-if="hotels.length > 0"
    class="new_order_item hotel-fields"
  >
    <div class="hotel-select">
      <div class="form-group select optional inline">
        <HotelLabelName />

        <HotelDropdown
          :hotel-required="hotelRequired"
          :hotels="hotels"
          :selected-hotel="selectedHotel"
          @hotel-emit="changeSelectedHotel"
        />
      </div>
    </div>

    <div
      v-if="stays.length > 0"
      class="room-quantity-select stay-type-select"
    >
      <HotelLabelStay />

      <HotelStayDropdown
        :selected-stay="selectedStay"
        :stays="stays"
        :package-qty="packageQty"
        @stay-change="changeSelectedStay"
      />
    </div>

    <div
      v-if="selectedHotel != null"
      class="room-quantity-select"
    >
      <div class="form-group select required inline">
        <HotelLabelQty />

        <HotelQty
          :hotel-max-qty="hotelMaxQty"
          :hotel-min-qty="hotelMinQty"
          :package-qty="packageQty"
          :qty="qty"
          @qty-change="changeQty"
        />
      </div>
    </div>

    <HotelQtyError v-if="displayError" />
  </div>
</template>

<script>
import HotelDropdown from '../hotels/dropdown-hotel-name'
import HotelLabelName from '../hotels/dropdown-label-name'
import HotelLabelQty from '../hotels/dropdown-label-qty'
import HotelLabelStay from '../hotels/dropdown-label-stay'
import HotelQty from '../hotels/dropdown-qty'
import HotelQtyError from '../hotels/room-qty-error'
import HotelStayDropdown from '../hotels/dropdown-stay'
import SharedData from '../hotels/shared'


export default Object.assign({}, SharedData, {
  components: {
    HotelDropdown,
    HotelLabelName,
    HotelLabelQty,
    HotelLabelStay,
    HotelQty,
    HotelQtyError,
    HotelStayDropdown
  },
})
</script>
