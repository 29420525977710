<template>
  <div
    id="cont-button"
    class="order-item-form"
  >
    <div
      id="new_order_item"
      novalidate="novalidate"
      class="simple_form new_order_item"
    >
      <div class="submit">
        <label>
          &#8203;
        </label>
        <div class="form-group">
          <input
            type="submit"
            name="commit"
            data-ga-event-category="Package-Form"
            data-ga-event-action="Submit"
            :value="formBtnText"
            :disabled="disableButton || soldOut"
            class="btn btn-primary btn-block"
            @click="createOrderItem"
          >
        </div>
      </div>
    </div>

    <div id="app">
      <ClearQuoteCartModal
        v-show="isModalVisible"
        @close="closeModal"
        @confirm="modalConfirmed"
      />
    </div>


    <div class="errors">
      {{ errs }}
    </div>
  </div>
</template>

<script>
import Api from '../../shared/cart/cart_api'
import ClearQuoteCartModal from '../../shared/add_to_cart_forms/bottom_form/clear_quote_cart_modal'
import { mapActions, mapMutations } from 'vuex'

export default {

  components: {
    ClearQuoteCartModal
  },

  props: {
    'formBtnText': {
      default: 'Continue',
      type: String
    },
    'idpackage': {
      default: 0,
      type: Number
    },
    'qtyhotel': {
      default: 0,
      type: Number
    },
    'qtypackage': {
      default: 0,
      type:Number
    }
  },

  data() {
    return {
      disableButton: false,
      errs: null,
      isModalVisible: false,
      validPkg: true
    }
  },


  computed: {
    hotelQty() {
      if (this.idhotel == null) {
        return 0
      } else {
        return this.qtyhotel
      }
    },

    cartDisabled(){
      return this.$store.state.order.order.disabled_cart
    },
    postBody() {
      if(document.getElementById('hotelAbTest')){
        return JSON.stringify({
          order_item: { ...this.abTestBody, ...this.orderItemBody }
        })
      } else {
        return JSON.stringify({
          order_item: this.orderItemBody
        })
      }
    },

    abTestBody(){
      return {
        ab_testing: true
      }
    },

    orderItemBody() {
      return {
        hotel_id: this.idhotel,
        package_id: this.idpackage,
        quantity: this.$store.state.packages.packageQty,
        room_quantity: this.hotelQty,
        stay_id: this.idstay
      }
    },

    soldOut() {
      return !this.$store.state.packages.validGpePackage
    }
  },

  watch: {
    'this.$store.state.packages.validGpePackage': function() {
      this.disableButton = true
      return true
    }
  },

  mounted() {
    this.validPkg = this.$store.state.packages.selectedPackage['valid_package?']
    this.$store.commit('packages/updateValidGpePackage', this.validPkg)
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('cart', ['displayCart']),
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },

    createOrderItem() {
      if (this.disableButton || this.validPkg != true) { return }

      if(this.cartDisabled) {
        this.showModal()
      } else {
        this.addOrderItem()
      }
    },
    modalConfirmed(){
      this.cartDisabled = false
      this.addOrderItem()
      this.closeModal()
    },
    addOrderItem(){

      this.disableButton = true
      this.errs = null

      Api.createOrderItem(this.postBody).then(response => {
        if (response.id) {
          this.getBackendOrder().then(order =>{
            if(order.id){
              this.displayCart()
            }
          })
        } else {
          this.errs = response.errors || this.$t('formPackage.error')
        }

        this.disableButton = false
      })
    }
  }
}
</script>
