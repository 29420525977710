<template>
  <div
    v-if="term.payment_method != 'internal_credit'"
    class="payment-term-display"
  >
    <TermAmountDisplay
      :header="formattedHeader"
      :header-sub-text="dueOrPaidDateText"
      :amount="formattedAmount"
      :extraclass="`payment-${paymentNum}`"
    />
  </div>
</template>

<script>
/* global locale */
import CurrencyApi from '../../../default/currency_convert/currency-api'
import TermAmountDisplay from '../../checkout/stripe_elements/term_amount_display'
import moment from 'moment'

export default {
  components: {
    TermAmountDisplay
  },

  props: {
    term: {
      default: null,
      type: Object
    },
    paymentNum: {
      default: null,
      type: Number
    }
  },

  computed: {
    currency (){
      return this.order.charge_currency
    },
    order(){
      return this.$store.state.order.order
    },
    dueDate() {
      if (this.term.due_date == undefined) {
        return new Date()
      } else {

        return moment(this.term.due_date)._d
      }
    },
    dueOrPaidDateText() {
      if (this.term.status && this.term.status == 'passed') {
        return this.$t('paidDate', {date: this.formattedDate})
      } else {
        return this.$t('dueDate', {date: this.formattedDate})
      }
    },
    formattedAmount() {
      if(this.term.payment_method == 'internal_credit'){
        return CurrencyApi.formatAmount(0, this.currency)
      } else {
        return CurrencyApi.formatAmount(this.term.amount / 100.0, this.currency)
      }
    },

    formattedDate() {
      if (this.dueDate <= new Date() && this.term.status != 'passed') {
        return this.$t('today')
      } else {
        return this.dueDate.toLocaleString(locale, {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        })
      }
    },
    formattedHeader() {
      return `${this.$t('paymentNum', { paymentNum: this.paymentNum })}`
    }
  },
}
</script>
