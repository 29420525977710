<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="quote-modal">
        <section
          id="modalDescription"
          class="modal-body"
        >
          <div class="clear-cart-text">
            This will clear your cart!
          </div>
        </section>
        <footer class="modal-footer">
          <div name="footer">
            <button
              class="modal-btn add"
              @click="confirm"
            >
              Add
            </button>
            <button
              class="modal-btn cancel"
              @click="close"
            >
              Cancel
            </button>
          </div>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'ClearQuoteCartModal',
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>
