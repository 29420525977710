<template>
  <section
    :class="[(reviewingOrder ? 'open' : '', 'sidebar-cart'),
             (guestCheckout ? true : '', 'guest-checkout')]"
  >
    <!-- <section class="sidebar-cart"> -->
    <h4>{{ $t('cart.yourItems') }}</h4>
    <CartDefault
      v-if="false"
    />
  </section>
</template>

<script>
import CartDefault from '../cart/cart'

export default {
  name: 'SidebarCart',
  components: {
    CartDefault
  },

  props: {
    currentsection: {
      default: null,
      type: String
    },
    number: {
      default: null,
      type: Number
    }},

  data() {
    return {
      sectionName: 'cart'
    }
  },

  computed: {
    reviewingOrder() {
      if (this.currentsection == 'review' || this.currentsection == 'payment') {
        return false
      } else {
        return true
      }
    },
    guestCheckout() {
      if(document.getElementById('customerSignoutLink')) {
        return true
      }else {
        return false
      }
    }
  }
}
</script>
