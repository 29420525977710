<template>
  <div
    v-if="customerPointsAvailable > 0"
    class="roomcash-points-awards"
  >
    <div
      v-if="customerPointsAvailable > 0"
      class="cart-points-selection border-bottom-ccc padding-vertical-10"
    >
      <input
        id="roomcash-points"
        v-model="roomcashAdded"
        class="boolean optional"
        type="checkbox"
        @change="updatePoints()"
      >
      <label
        for="roomcash-points"
        class="header font-weight-300 padding-left-10"
      >
        {{ $t('checkout.addPoints',{'programName': programName }) }}: <span class="lighter">{{ rewardPoints }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import CartApi from '../../cart/cart_api'
export default {
  data(){
    return {
      customerPointsAvailable: 0,
      programName: '',
      roomcashAdded: false,
    }
  },

  computed:{
    rewardPoints(){
      return this.$store.state.checkout.rewardPoints
    }
  },

  created (){
    this.fetchCustomerPoints()
  },

  methods:{
    ...mapMutations('order', ['updateOrder', 'updateLoading']),
    ...mapMutations('checkout', ['updateRewardPoints']),

    updatePoints(){
      this.updateLoading(true)
      CartApi.addCustomerPoints(JSON.stringify({apply: this.roomcashAdded})).then (order => {
        this.updateOrder(order)
        this.updateLoading(false)
      })
    },
    fetchCustomerPoints(){
      CartApi.fetchCustomerPoints().then (res => {
        if(res.points > 0){
          this.roomcashAdded = res.roomcash_applied > 0
          this.updateRewardPoints(res.reward_points)
          this.programName = res.program_name
          this.customerPointsAvailable = res.points
        }
      })
    }
  }
}
</script>
