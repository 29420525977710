<template>
  <div
    v-if="(protectable || quoteOrder) && !zeroDollarChangeOrder"
    id="protectGroupForm"
    class="protect-group-form"
  >
    <div
      class="protect-group-refundable open "
    >
      <h2 class="protect-group-title expandable-header">
        *{{ $t('checkout.protectGroup.refundableBooking') }}
      </h2>
    </div>
    <div
      class="refundable-display-box level mb-0"
      @click="selectRefundable()"
    >
      <div class="refundable-recommended-bubble level-item level-left">
        <div
          class="protect-group-select-container"
        >
          <div>
            <i
              class="protect-group-select refundable"
            >

              <i
                id="refundable-radio"
                class="fa fa-circle small-protect-group-select refundable"
              />
            </i>
          </div>
        </div>
        <div class="refundable-statement">
          <b>{{ $t('checkout.protectGroup.refundableBooking') }}</b>
        </div>
        <div class="protect-group-recommended-bubble-div mx-auto">
          <div class="protect-group-recommended-bubble">
            {{ $t('checkout.protectGroup.recommended') }}
          </div>
        </div>
      </div>
      <div
        id="eventProtectGroupPremiumAmount"
        class="event-protect-group-premium-amount level-right"
      >
        {{ order.protect_group_cost_decorated }}
      </div>
    </div>
    <div class="refundable-perks-chart">
      <div class="refundable-perks-chart-row tile">
        <div class="refundable-perks-chart-cell tile is-6">
          <i
            class="fa fa-check"
            aria-hidden="true"
          />
          {{ $t('checkout.protectGroup.unexpectedPersonalProblem') }}
        </div>
        <div class="refundable-perks-chart-cell tile is-6">
          <i
            class="fa fa-check"
            aria-hidden="true"
          />
          {{ $t('checkout.protectGroup.homeEmergency') }}
        </div>
      </div>
      <div class="refundable-perks-chart-row tile">
        <div class="refundable-perks-chart-cell tile is-6">
          <i
            class="fa fa-check"
            aria-hidden="true"
          />
          {{ $t('checkout.protectGroup.publicTransportFailure') }}
        </div>
        <div class="refundable-perks-chart-cell tile is-6">
          <i
            class="fa fa-check"
            aria-hidden="true"
          />
          {{ $t('checkout.protectGroup.relativeSickness') }}
        </div>
      </div>
      <div class="refundable-perks-chart-row tile">
        <div class="refundable-perks-chart-cell tile is-6">
          <i
            class="fa fa-check"
            aria-hidden="true"
          />
          {{ $t('checkout.protectGroup.privateVehicleFailure') }}
        </div>
        <div class="refundable-perks-chart-cell tile is-6">
          <i
            class="fa fa-check"
            aria-hidden="true"
          />
          {{ $t('checkout.protectGroup.preExistingCondition') }}
        </div>
      </div>
      <div class="refundable-perks-chart-row tile">
        <div class="refundable-perks-chart-cell tile is-6">
          <i
            class="fa fa-check"
            aria-hidden="true"
          />
          {{ $t('checkout.protectGroup.emergencyRecall') }}
        </div>
        <div class="refundable-perks-chart-cell tile is-6">
          <i
            class="fa fa-check"
            aria-hidden="true"
          />
          {{ $t('checkout.protectGroup.andManyMore') }}
        </div>
      </div>
      <div class="single-refundable-perks-chart-row tile">
        <div class="refundable-perks-chart-cell">
          <i
            class="fa fa-check"
            aria-hidden="true"
          />
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="$t('checkout.protectGroup.covidRefund')" />
        </div>
        <!-- <div class="refundable-perks-chart-cell tile is-6" /> -->
      </div>
      <div class="protect-group-upgrade-statement">
        <p
          v-html="$t('checkout.protectGroup.upgrade20Statement')"
        />
        <p>
          {{ $t('checkout.protectGroup.weDontRefundCovid') }}
        </p>
        <p
          class="small administered-by"
          v-html="$t('checkout.protectGroup.administeredBy')"
        />
        <!--eslint-enable-->
      </div>
    </div>
    <div
      class="nonrefundable-display-box level"
      @click="selectNonRefundable()"
    >
      <div>
        <i
          class="protect-group-select"
        >
          <i
            id="non-refundable-radio"
            class="fa fa-circle small-protect-group-select refundable"
          />
        </i>
      </div>
      <label class="nonrefundable-label"> {{ $t('checkout.protectGroup.nonRefundableBooking') }} </label>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import CartApi from '../cart/cart_api'
export default {
  name: 'CheckoutProtected',

  props: {
    updateterms: {
      default: null,
      type: String
    }
  },

  data() {
    return {
      eventProtectable: true,
      packagesProtectable: true,
      quoteOrder: true,
      maxProtectable: 0,
      zeroDollarChangeOrder: false
    }
  },
  computed:{
    protectable(){
      return this.eventProtectable && this.packagesProtectable
    },
    order (){
      return this.$store.state.order.order
    }
  },
  created(){
    CartApi.getProtectGroupInfo().
      then(info => {
        this.setInitialValues(info)
        this.setProtectGroup(info)
        info.order_protected ? this.selectRefundable() : null
      })
  },
  methods: {
    ...mapMutations('checkout', [
      'customerMadeProtectGroupDecision',
    ]),
    ...mapMutations('order', ['updateOrder', 'updateLoading']),
    selectRefundable(){
      this.highlightRefundButton()
      this.emitChange(this.order.protect_group_cost)
      this.customerMadeProtectGroupDecision()
    },
    selectNonRefundable(){
      this.highlightNonRefundableButton()
      this.emitChange(0)
      this.customerMadeProtectGroupDecision()
    },
    highlightRefundButton(){
      this.clearRadios()
      let radio = document.getElementById('refundable-radio')
      radio.classList.add('chosen')
    },
    highlightNonRefundableButton(){
      this.clearRadios()
      let radio = document.getElementById('non-refundable-radio')
      radio.classList.add('chosen')
    },
    emitChange(amount){
      this.updateLoading(true)
      let body = JSON.stringify(
        { order: { protect_group_amount: amount } }
      )
      CartApi.changeOrder(body).then( order => {
        this.updateOrder(order)
        this.updateLoading(false)
      })
    },
    setInitialValues(info){
      this.eventProtectable = info.event_pg_enabled
      this.packagesProtectable = info.package_pg_enabled
      this.quoteOrder = info.quote_order
      this.zeroDollarChangeOrder = info.quote_order_hide_protect_group
      this.maxProtectable = info.event_pg_premium
      this.$store.state.checkout.protectGroupPremiumDecorated = info.event_pg_premium_decorated
      this.$store.state.checkout.protectGroupPremium = info.event_pg_premium
      this.$store.state.checkout.protectGroupCost = info.event_pg_premium
    },
    setProtectGroup(info){
      if (!info.event_pg_enabled || !info.package_pg_enabled || info.zero_dollar_change_order || info.zero_dollar_quote_order || info.event_pg_premium<=0){
        this.$store.state.checkout.protectGroupDecisionMade = true
      }
    },
    clearRadios() {
      let packagesForm = document.getElementById('protectGroupForm')
      let radios=Array.from(packagesForm.getElementsByClassName('small-protect-group-select'))
      radios.forEach(radio => {
        radio.classList.remove('chosen')
      })
    },
    selectRadioButton(){
      let packagesForm = document.getElementById('multiplePackagesForm')
      packagesForm.getElementsByClassName('small-protect-group-select')[0].classList.add('chosen')
    },
  }
}
</script>
