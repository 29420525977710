import VueLoader from '../../default/vue-loader'

import HotelOverview from '../contracted_hotels/contracted_hotel_overview'
import BottomForm from './bottom_form/form'
import H4HBottomForm from './h4h_bottom_form/form'
import HotelTable from './package_contracted_hotel_section/hotels-table'
import PackageAvailable from './bottom_form/package_available'
// import HotelIndex from './h4h_hotels/hotel_index'
import { store } from'../../default/store'

export default {
  loadBottomForm() {
    let packageDiv = document.getElementById('packageCartFormData')
    let contractHotelPage = document.getElementById('hotelPage')
    let h4hPackageDiv = document.getElementById('h4hPackageCartFormData')
    let noPurchasing = document.getElementById('packageCartFormAvailable')
    let hotelTable = document.getElementById('contractHotels')
    if (h4hPackageDiv) {
      VueLoader.loadVueComponent(H4HBottomForm, document.getElementById('addToCartVue'),store)
    } else if (packageDiv) {
      VueLoader.loadVueComponent(BottomForm, document.getElementById('addToCartVue'), store)
    } else if (noPurchasing) {
      VueLoader.loadVueComponent(PackageAvailable, document.getElementById('addToCartVue'), store)
    } else if (contractHotelPage) {
      this.loadContractedHotel()
    }

    if(hotelTable){
      VueLoader.loadVueComponent(HotelTable, document.getElementById('contractHotels'), store)
    }
  },

  async loadContractedHotel(){
    VueLoader.loadVueComponent(HotelOverview, document.getElementById('hotelPage'), store)
  }
}
