let numbers = {}

function setLocation() {
  let location = document.getElementById('country-code').dataset.code
  let showNumber = numbers['phone-' + location] ? location : 'default'
  $('.phone-numbers').addClass('hidden')
  $('.phone-' + showNumber).removeClass('hidden')
  return numbers[`phone-${showNumber}`]
}

document.addEventListener('turbo:load', () => {
  let numbersArray = document.getElementsByClassName('phone-numbers')

  if (numbersArray.length > 1) {
    for (var i = 0, len = numbersArray.length; i < len; i++) {
      numbers[numbersArray[i].id] = numbersArray[i].innerHTML
    }
    setLocation()
  }
})

export default function() {
  return setLocation()
}
