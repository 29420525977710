<template>
  <section class="checkout-payment-select open">
    <h2 class="payment-term-header expandable-header">
      <!-- Your Payments -->
      {{ $t("checkout.payment.choosePaymentTerms") }}
    </h2>

    <div class="payment-select field">
      <select
        v-model="paymentCount"
        class="form-control select"
        @change="updatePaymentCount()"
      >
        <option
          v-for="p in paymentTermsForSelect"
          :key="p.value"
          :value="p.value"
        >
          {{ p.text }}
        </option>
      </select>

      <div
        v-if="ers"
        class="errors"
      >
        <span>{{ ers }}</span>
        <span
          class="delete"
          @click="ers = null"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Api from '../cart_api'
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    options: {
      default: () => [],
      type: Array
    }
  },

  data() {
    return {
      ers: null,
      paymentCount: 1,
    }
  },
  computed: {
    order(){
      return this.$store.state.order.order
    },
    orderpaymentcount(){
      return this.$store.state.order.order.payment_count
    },
    paymentTermsForSelect() {
      return this.options.map((option) => {
        return { text: this.$t(`payment_term_${option}`), value: option }
      })
    },
  },
  watch: {
    orderpaymentcount() {
      this.paymentCount = this.orderpaymentcount
    },
  },

  created() {
    this.paymentCount = this.orderpaymentcount
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('order', ['updateLoadingTerms']),
    updatePaymentCount() {
      this.updateLoadingTerms(true)
      Api.changeOrder(JSON.stringify({ order: { payment_count: this.paymentCount } })).then((order) => {
        this.getBackendOrder().then( () => this.updateLoadingTerms(false) )
        if (order.id == undefined) {
          this.paymentCount = this.orderpaymentcount
          this.ers = this.$t('packageNotUpdated')
        }

      })
    },
  },
}
</script>
