import VueLoader from '../../default/vue-loader'

import H4HBreadcrumb from '../add_to_cart_forms/h4h_hotels/h4h_breadcrumb'
// import CheckoutBasic from './checkout_basic'
import ReworkedCheckout from './reworked_checkout_basic'
import StripeElements from './stripe_elements/checkout_page'
import CartEdit from './cart_edit'
import CheckoutJqueryCarousel from './checkout_jquery_carousel'

import { store } from'../../default/store'

document.addEventListener('turbo:load', () => {
  VueLoader.loadVueComponent(H4HBreadcrumb, document.getElementById('h4h-breadcrumb'), store)
})

export default   {
  loadWithBasicAddOn() {
    return VueLoader.loadVueComponent(ReworkedCheckout, document.getElementById('checkoutVue'), store)
  },

  loadStripeElements() {
    VueLoader.loadVueComponent(CartEdit, document.getElementById('cartEdit'), store)
    return VueLoader.loadVueComponent(StripeElements, document.getElementById('checkoutVue'), store)
  },

  loadWithJqueryAddOn() {
    return CheckoutJqueryCarousel.loadWithAddOnAndCarousel(this.loadWithBasicAddOn())
  },

  async loadWithAddOn() {
    return this.loadStripeElements()
  }
}
