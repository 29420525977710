<template>
  <div
    class="checkout-vue-form"
    :class="[orderLoading ? 'loading' : '']"
  >
    <div class="loading-icon" />
    <Cart />
  </div>
</template>

<script>

import Cart from './stripe_elements/cart'
// import CheckoutReview from './review'

export default {
  components: {
    Cart,
    // CheckoutReview,
  },
  data() {
    return {
      SidebarCart:Cart,
      //   CheckoutReview: CheckoutReview,

    }
  },

  computed:{
    orderLoading(){
      return this.$store.state.order.loading
    },
  },

  created(){
    this.removeFooterMargin()
  },

  methods: {
    removeFooterMargin() {
      // this is stupid but I don't see another way to do it since it's only this page
      const f1Footer = document.getElementsByClassName('f1-footer')[0]
      const footer = document.querySelector('.footer')
      if(f1Footer) f1Footer.style = 'margin-top: 0'
      document.querySelector('footer').style = 'margin-top: 0'
      if(footer) {
        footer.style = 'margin-top: 0'
      }
    },
  }
}
</script>
